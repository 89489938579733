import axios from 'axios'
import _ from 'lodash'
import * as util from './util'

const LOAD_DEPLOYMENTS = 'LOAD_DEPLOYMENTS'
const ADD_DEPLOYMENT = 'ADD_DEPLOYMENT'
const REMOVE_DEPLOYMENT = 'REMOVE_DEPLOYMENT'
const UPDATE_DEPLOYMENT = 'UPDATE_DEPLOYMENT'
const SET_ACTIVE_DEPLOYMENTID = 'SET_ACTIVE_DEPLOYMENTID'
const SET_DEPLOYMENT_FILTERS = 'SET_DEPLOYMENT_FILTERS'
const RESET_STATE = 'RESET_STATE'

const baseState = {
  deployments: [],
  deploymentFilters: [],
  activeDeploymentId: null,
  deploymentStatusOptions: [
    { text: 'Active', value: 'OPEN' },
    { text: 'Archived', value: 'CLOSED' }
  ]
}

let state = baseState // eslint-disable-line prefer-const

const getters = {
  deployments: state => {
    let items = state.deployments
    for (let index = 0; index < state.deploymentFilters.length; index++) {
      const f = state.deploymentFilters[index]
      items = f(items)
    }
    return _.sortBy(items, ['title'])
  },
  hasClosedDeployments: state => state.deployments.some(d => d.state === 'CLOSED'),
  activeDeploymentId: state => state.activeDeploymentId,
  activeDeployment: state => {
    return state.deployments.find(d => d.id === state.activeDeploymentId)
  },
  deploymentsByTag: state => tag => {
    return _.sortBy(
      state.deployments.filter(
        d => d.state === 'OPEN' && d.tags.includes(tag)
      ),
      ['title']
    )
  },
  deploymentsCountByTag: (state, getters) => tag =>
    getters.deploymentsByTag(tag).length,
  deploymentById: state => id => {
    return state.deployments.find(d => d.id === id)
  },
  deploymentStatusDisplayName: state => value => {
    const option = state.deploymentStatusOptions.find(o => o.value === value)
    return option ? option.text : ''
  }
}

const mutations = {
  RESET_STATE: (state) => {
    state = baseState
  },
  SET_DEPLOYMENT_FILTERS: (state, filterFunctions) => {
    state.deploymentFilters = filterFunctions
  },
  LOAD_DEPLOYMENTS: (state, deployments) => {
    state.deployments = deployments
  },
  ADD_DEPLOYMENT: (state, deployment) => {
    state.deployments.push(deployment)
  },
  UPDATE_DEPLOYMENT: (state, deployment) => {
    state.deployments = [
      ...state.deployments.filter(a => a.id !== deployment.id),
      deployment
    ]
    state.activeDeploymentId = deployment.id
    console.log('state.activeDeploymentId set: ' + state.activeDeploymentId)
  },
  REMOVE_DEPLOYMENT: (state, deploymentId) => {
    state.deployments = state.deployments.filter(a => a.id !== deploymentId)
  },
  SET_ACTIVE_DEPLOYMENTID: (state, deploymentId) => {
    state.activeDeploymentId = deploymentId
    console.log('state.setActiveDeploymentId set inside SET_ACTIVE_DEPLOYMENTID: ' + state.activeDeploymentId)
  }
}

const actions = {
  clearDeployments: ({ commit }) => commit(LOAD_DEPLOYMENTS, []),
  setDeploymentFilters: ({ commit }, filterFunctions) => {
    commit(SET_DEPLOYMENT_FILTERS, filterFunctions)
  },
  setActiveDeploymentId: ({ commit }, id) => {
    commit(SET_ACTIVE_DEPLOYMENTID, id)
  },
  loadDeployment: async ({ commit, dispatch }, id) => {
    console.log('called loadDeployment in store with id:', id)
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/deployments/' + id, await util.getAuthorizationHeader())
      .then(result => {
        console.log('server returned deployment:', result.data)
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_DEPLOYMENT, JSON.parse(result.data.Item.data))
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  loadDeployments: async ({ commit, dispatch }) => {
    console.log('loadDeployments called')
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/deployments/', await util.getAuthorizationHeader())
      .then(result => {
        console.log('data returned from getting deployments', result)
        dispatch('setLoadingIndicator', false)
        const data = result.data ? result.data.map(d => JSON.parse(d.data)) : result.data
        commit(LOAD_DEPLOYMENTS, data)
        return data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  addDeployment: async ({ commit, dispatch }, deployment) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .post('/api/v1/deployments/', deployment, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(ADD_DEPLOYMENT, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  updateDeployment: async ({ commit, dispatch }, deployment) => {
    dispatch('setLoadingIndicator', true)
    // alert('DeploymentStore.updateDeployment: ' + JSON.stringify(deployment, null, 2))
    return axios
      .put('/api/v1/deployments/' + deployment.id, deployment, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_DEPLOYMENT, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  removeDeployment: ({ commit, dispatch }, deploymentId) => {
    dispatch('setLoadingIndicator', true)
    commit(REMOVE_DEPLOYMENT, deploymentId)
    dispatch('setLoadingIndicator', false)
  },
  reset_state: ({ commit }) => {
    console.log('reset_state from within deployment stores')
    commit(RESET_STATE)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

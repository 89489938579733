<template><div class="mx-auto" style="max-width: 500px">
  <h1 class="headline mb-3"><v-icon class="header-icon">mdi-account</v-icon> Password Reset</h1>
  <p>Passwords are reset in a two step process. A verification code will be sent to your email address. Use that code in the second step to update your password.</p>

<v-stepper v-model="currentStep" non-linear>
  <v-stepper-header>
    <v-stepper-step editable step="1">Send verification code</v-stepper-step>
    <v-divider></v-divider>
    <v-stepper-step editable step="2">Update Password</v-stepper-step>
  </v-stepper-header>

  <v-stepper-items>
    <v-stepper-content step="1">
      <v-form ref="form" v-model="validCodeSendForm" class="mt-1">
        <v-text-field
          outlined
          v-model="email"
          :rules="[rules.required]"
          label="Email"
          required
        ></v-text-field>
        <v-btn color="primary" @click="sendCode" :disabled="!validCodeSendForm">Send Verification Code</v-btn>
      </v-form>
    </v-stepper-content>
    <v-stepper-content step="2">
      <v-form ref="form" v-model="validUpdateForm">
        <v-text-field
          outlined
          v-model="email"
          :rules="[rules.required]"
          label="Email"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          label="New Password"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-text-field
          outlined
          v-model="code"
          :rules="[rules.required]"
          label="Verification Code"
          required
        ></v-text-field>
        <v-btn color="primary" @click="updatePassword" :disabled="!validUpdateForm">Update Password</v-btn>
      </v-form>
    </v-stepper-content>
  </v-stepper-items>
</v-stepper>

</div></template>

<script>
import { Auth } from 'aws-amplify'

export default {
  name: 'password-reset',
  data() {
    return {
      email: '',
      password: '',
      code: '',
      currentStep: 1,
      validUpdateForm: false,
      validCodeSendForm: false,
      showPassword: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      }
    }
  },
  computed: {
    signedIn() {
      // return this.$store.state.LoginStore.signedIn
      return false
    }
  },
  methods: {
    sendCode() {
      Auth.forgotPassword(this.email)
        .then(user => {
          this.currentStep = 2
        })
        .catch(err => console.log(`Auth.forgotPassword threw: ${err}`))
    },
    updatePassword() {
      // Collect confirmation code and new password , then
      Auth.forgotPasswordSubmit(this.email, this.code, this.password)
        .then(user => {
          console.log('new user', user)
          this.$router.push({ name: 'account-login' })
        })
        .catch(err => console.log(`Auth.forgotPasswordSubmit threw: ${err}`))
    }
  }
}
</script>

<style scoped>
.v-stepper__wrapper {
  overflow: initial;
}
</style>

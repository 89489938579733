import axios from 'axios'
import _ from 'lodash'
import * as util from './util'

const LOAD_CONTACTS = 'LOAD_CONTACTS'
const ADD_CONTACT = 'ADD_CONTACT'
// const REMOVE_CONTACT = 'REMOVE_CONTACT'
const UPDATE_CONTACT = 'UPDATE_CONTACT'
const SET_CONTACT_FILTERS = 'SET_CONTACT_FILTERS'
const RESET_STATE = 'RESET_STATE'

const baseState = {
  contacts: [],
  contactFilters: [],
  activeContactId: null,
  contactStatusOptions: [
    { text: 'Active', value: 'OPEN' },
    { text: 'Archived', value: 'CLOSED' }
  ]
}

let state = baseState // eslint-disable-line prefer-const

const getters = {
  contacts: state => {
    let items = state.contacts
    for (let index = 0; index < state.contactFilters.length; index++) {
      const f = state.contactFilters[index]
      items = f(items)
    }
    return _.sortBy(items, ['lastName'])
  },
  activeContact: state => {
    return state.contacts.find(d => d.id === state.activeContactId)
  },
  contactById: state => id => state.contacts.find(d => d.id === id),
  contactStatusDisplayName: state => value => {
    const option = state.contactStatusOptions.find(o => o.value === value)
    return option ? option.text : ''
  }
}

const mutations = {
  RESET_STATE: (state) => {
    state = baseState
  },
  SET_CONTACT_FILTERS: (state, filterFunctions) => {
    state.contactFilters = filterFunctions
  },
  LOAD_CONTACTS: (state, contacts) => {
    state.contacts = contacts
  },
  ADD_CONTACT: (state, contact) => {
    state.contacts.push(contact)
  },
  UPDATE_CONTACT: (state, contact) => {
    state.contacts = [...state.contacts.filter(a => a.id !== contact.id), contact]
    state.activeContactId = contact.id
  },
  REMOVE_CONTACT: (state, contactId) => {
    state.contacts = state.contacts.filter(a => a.id !== contactId)
  }
}

const actions = {
  clearContacts: ({ commit }) => commit(LOAD_CONTACTS, []),
  setContactFilters: ({ commit }, filterFunctions) => {
    commit(SET_CONTACT_FILTERS, filterFunctions)
  },
  loadContact: async ({ commit, dispatch }, id) => {
    console.log('called loadContact in store with id:', id)
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/contacts/' + id, await util.getAuthorizationHeader())
      .then(result => {
        console.log('server returned contact:', result.data)
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_CONTACT, JSON.parse(result.data.Item.data))
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  loadContacts: async ({ commit, dispatch }, deploymentId) => {
    console.log('loadContacts called with deploymentId:', deploymentId)
    dispatch('setLoadingIndicator', true)
    return axios
      .get(deploymentId ? `/api/v1/contacts/deployment/${deploymentId}` : '/api/v1/contacts/', await util.getAuthorizationHeader())
      .then(result => {
        console.log('data returned from getting contacts', result)
        dispatch('setLoadingIndicator', false)
        const data = result.data ? result.data.map(d => JSON.parse(d.data)) : result.data
        commit(LOAD_CONTACTS, data)
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  addContact: async ({ commit, dispatch }, contact) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .post('/api/v1/contacts/', contact, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(ADD_CONTACT, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  updateContact: async ({ commit, dispatch }, contact) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .put('/api/v1/contacts/' + contact.id, contact, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_CONTACT, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  removeContact: async ({ commit, dispatch }, contactId) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .delete('/api/v1/contacts/' + contactId, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
        dispatch('loadContacts')
        // commit(UPDATE_CONTACT, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  reset_state: ({ commit }) => {
    console.log('reset_state from within contact stores')
    commit(RESET_STATE)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="issue-dashboard">
        <!-- <v-expansion-panels accordion flat>
      <v-expansion-panel class="filterContainer">
        <v-expansion-panel-header>
          <div>
            <v-icon small>{{filterIcon}}</v-icon> Filters
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-divider />
          <v-row align="center"><v-checkbox v-model="showAll" label="Include Closed Issues" dense @click="updateFilters()"></v-checkbox></v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="4" align-self="center">
              <v-checkbox v-model="filterAssignedTo" label="Assigned To" dense></v-checkbox>
            </v-col>
            <v-col align-self="center">
              <v-select
                outlined
                dense
                :items="users"
                :item-text="getUserDisplayName"
                item-value="id"
                v-model="filterAssignedToPersonId"
              ></v-select>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col cols="4">
              <v-checkbox v-model="filterOpenedBy" label="Opened By" dense></v-checkbox>
            </v-col>
            <v-col>
              <v-select
                outlined
                dense
                :items="users"
                :item-text="getUserDisplayName"
                item-value="id"
                v-model="filterOpenedByPersonId"
              ></v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->

    <div class="text-xs-left green lighten-5 d-flex justify-space-between align-center">
      <v-checkbox class="text-body-2 mx-2" v-model="showAll" label="Include Closed Issues" dense @click="updateFilters()"></v-checkbox>
      <v-btn v-if="canEdit" class="ma-2" small color="success" :to="{ name: 'issue-new', query: { deploymentId: this.$route.params.id}}">Add Issue</v-btn>
    </div>
    <v-data-table
      :headers="showAll ? allHeaders : headers"
      :items="issues"
      item-key="id"
      class="elevation-0"
      :items-per-page="15"
      hide-default-footer
      :loading="isLoading"
      loading-text="Loading... Please Wait"
    >
      <template v-slot:[`item.title`]="{ item }">
        <router-link :to="{ name: 'issue-detail', params: { id: item.id }}">{{ item.title }}</router-link>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <span class="status">{{ issueStatusDisplayName(item.state) }}</span>
      </template>
      <template v-show="showAll" v-slot:[`item.resolution`]="{ item }">
        {{ issueResolutionDisplayName(item.resolution) }}
      </template>
      <template v-slot:[`item.assignedToPersonId`]="{ item }">
        {{ getUserDisplayName(item.assignedToPersonId) }}
      </template>
      <template v-slot:[`item.createdByPersonId`]="{ item }">
        {{ getUserDisplayName(item.createdByPersonId) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openFilter, deploymentFilter } from '@/store/filters'
// import _ from 'lodash'

// const filterIssuesByDeploymentId = (issues, id) => issues.filter(i => i.deployment && i.deployment.id === id)
// const filterIssuesByOpenStatus = (issues) => issues.filter(i => i.state ? i.state.startsWith('OPEN') : false)
// const filterIssuesByClosedStatus = (issues) => issues.filter(i => i.state === 'CLOSED')
// const filterIssuesByResolutionType = (issues, resolution) => issues.filter(i => i.resolution === resolution)
// const filterIssuesByAssignedTo = (issues, id) => issues.filter(i => i.assignedToPersonId === id)
// const filterIssuesByOpenedBy = (issues, id) => issues.filter(i => i.openedByPersonId === id)
// const sortIssuesByTitle = (issues) => _.sortBy(issues, ['title'])

export default {
  name: 'deployment-issues',
  created() {
    window.vm = this
    this.$store.dispatch('clearIssues')
    this.$store.dispatch('setIssueFilters', [openFilter, deploymentFilter(this.$route.params.id)])
    this.$store.dispatch('loadIssues', this.$route.params.id) // pass deploymentId to limit query scope
    this.$store.dispatch('loadDeployments')
    this.$store.dispatch('loadUsers')
  },
  computed: {
    ...mapGetters([
      'issueResolutionDisplayName',
      'issueStatusDisplayName',
      'issues',
      'userById',
      'users',
      'deploymentById',
      'deployments',
      'canEdit',
      'isLoading'
    ]),
    filterIcon() {
      return this.showAll | this.filterAssignedTo | this.filterOpenedBy ? 'mdi-filter-plus-outline' : 'mdi-filter-outline'
    }
  },
  data() {
    return {
      filters: {
        showAll: {
          enabled: false,
          type: 'checkbox',
          data: null,
          filter: (issues) => issues,
          hasDisabledFilter: true,
          disabledFilter: (issues) => issues.filter(i => i.state !== 'CLOSED')
        }
        // // showAll: {
        // //   enabled: false,
        // //   type: 'checkbox',
        // //   data: null,
        // //   function:
        // // }
        // // assignedTo: {
        // //   enabled: false,
        // //   type: 'select',
        // //   data: null,
        // //   function: (issues, id) => issues.filter(i => i.assignedToPersonId === id)
        // // },
        // // openedBy: {
        // //   enabled: false,
        // //   type: 'checkbox',
        // //   data: null,
        // //   function: (issues, id) => issues.filter(i => i.assignedToPersonId === id)
        // // }
      },
      filterAssignedTo: false,
      filterAssignedToPersonId: null,
      filterOpenedBy: false,
      filterOpenedByPersonId: null,
      showAll: false,
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Status', value: 'state' },
        { text: 'Assigned To', value: 'assignedToPersonId' },
        { text: 'Opened By', value: 'createdByPersonId' }
      ],
      allHeaders: [
        { text: 'Title', value: 'title' },
        { text: 'Status', value: 'state' },
        { text: 'Resolution', value: 'resolution' },
        { text: 'Assigned To', value: 'assignedToPersonId' },
        { text: 'Opened By', value: 'createdByPersonId' }
      ]
    }
  },
  watch: {
    filterAssignedToPersonId: function() {
      this.filterAssignedTo = true
    },
    filterOpenedByPersonId: function() {
      this.filterOpenedBy = true
    }
  },
  methods: {
    getDeploymentDisplayName(deploymentId) {
      const deployment = this.$store.getters.deploymentById(deploymentId)
      return deployment ? deployment.title : ''
    },
    getUserDisplayName(userId) {
      const user = this.$store.getters.userById(userId)
      return user ? `${user.firstName} ${user.lastName}` : ''
    },
    updateFilters() {
      const filters = [deploymentFilter(this.$route.params.id)]
      if (!this.showAll) {
        filters.push(openFilter)
      }
      this.$store.dispatch('setIssueFilters', filters)
    }
  }
}
</script>

<style>
th {
  white-space: nowrap;
}
.status {
  white-space: nowrap;
  font-size: .8rem;
}
.expanded-row {
  border-bottom: none !important;
}
.expandsection {
  padding: 0.5rem 1.5rem;
  background-color: #efefef;
}

.filterContainer {
  border: #aceace solid 1px;
}
</style>

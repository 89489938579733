import axios from 'axios'

const state = {}
const getters = {}
const mutations = {}
const actions = {
  sendFeedback: ({ dispatch, getters }, message) => {
    console.log(getters.profile)
    if (getters.profile) {
      const user = {
        firstName: getters.profile.firstName,
        lastName: getters.profile.lastName,
        email: getters.profile.email,
        id: getters.profile.id,
        role: getters.profile.role,
        organizationId: getters.profile.organizationId
      }
      dispatch('setLoadingIndicator', true)
      axios
        .post('https://form.yakuku.com/', {
          user: user,
          message: message.message,
          application: 'deploystatusfeedback'
        })
        .then(result => {
          console.log(result)
          dispatch('setLoadingIndicator', false)
        })
        .catch(error => {
          console.log(error)
          dispatch('setLoadingIndicator', false)
          throw error
        })
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

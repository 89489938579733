import axios from 'axios'
// import _ from 'lodash'
import * as util from './util'

const LOAD_USERS = 'LOAD_USERS'
const RESET_STATE = 'RESET_STATE'

const baseState = {
  users: []
}

let state = baseState // eslint-disable-line prefer-const

const getters = {
  users: state => state.users,
  userById: state => id => {
    return state.users.find(d => d.id === id)
  },
  getUserDisplayName: state => id => {
    const user = state.users.find(d => d.id === id)
    return user ? `${user.firstName} ${user.lastName} (${user.email})` : ''
  }
}

const mutations = {
  RESET_STATE: (state) => {
    state = baseState
  },
  LOAD_USERS: (state, users) => {
    state.users = users
  }
}

const actions = {
  loadUsers: async ({ commit, dispatch }) => {
    console.log('loadUsers called')
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/users/', await util.getAuthorizationHeader())
      .then(result => {
        console.log('data returned from getting users', result)
        dispatch('setLoadingIndicator', false)
        const data = result.data ? result.data.map(d => JSON.parse(d.data)) : result.data
        commit(LOAD_USERS, data)
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  reset_state: ({ commit }) => {
    commit(RESET_STATE)
  },
  acceptInvitation: async ({ dispatch }, data) => {
    console.log('acceptInvitation called')
    dispatch('setLoadingIndicator', true)
    return axios
      .post('/api/v1/users/accept', data, await util.getAuthorizationHeader())
      .then(result => {
        console.log('data returned from acceptInvitation', result)
        dispatch('setLoadingIndicator', false)
        //TODO: how do we detect whether it worked?
        return true
        // commit(LOAD_USERS, data)
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
        return false
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

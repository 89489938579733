
import { Auth } from 'aws-amplify'

export const getAuthorizationHeader = async () => {
  return {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
}

<template><div>
  <SecurityTabs></SecurityTabs>
  <router-view></router-view>
</div></template>

<script>
// eslint-disable-next-line
import SecurityTabs from '@/components/SecurityTabs.vue'

export default {
  name: 'security',
  components: {
    SecurityTabs
  }
}
</script>

<template>
  <div>
    <div class="text-xs-left green lighten-5 d-flex justify-space-between align-center">
      <v-checkbox class="text-body-2 mx-2" v-model="showAll" label="Include Closed Invitations" dense @click="updateFilters()"></v-checkbox>
      <v-btn v-if="canEdit" class="ma-2" small color="success" @click="openNewDialog">Add Invitation</v-btn>
    </div>
    <v-data-table
      :headers="showAll ? allHeaders : headers"
      :items="invitations"
      item-key="id"
      class="elevation-0"
      :items-per-page="15"
      hide-default-footer
      :loading="isLoading"
      loading-text="Loading... Please Wait"
    >
      <template v-slot:[`item.expiration`]="{ item }">
        {{ new Date(item.expiration).toLocaleDateString() }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>

    </v-data-table>

    <!-- Add Invitation Dialog -->
    <v-dialog v-model="showingNewDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="showingNewDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add Invitation</v-toolbar-title>

        </v-toolbar>
        <v-card-text>
          <v-form v-model="valid" class="mt-3">
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  dense
                  label="Email"
                  v-model="invitationForm.email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  dense
                  label="Name"
                  v-model="invitationForm.name"
                  :rules="nameRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
              <v-row>
                <v-col>
                  <v-select
                    outlined
                    dense
                    :items="invitationPermissionOptions"
                    label="Permission"
                    v-model="invitationForm.role"
                    :rules="permissionRules"
                    required
                  ></v-select>
                </v-col>
              </v-row>

            <v-row class="text-xs-left green lighten-5 pa-2">
              <v-btn type="submit" class="mr-2" color="success" @click.native="saveNew" :disabled="!valid">Save</v-btn>
              <v-btn class="mr-2" text @click="showingNewDialog=false">Cancel</v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- CONFIRM REMOVE INVITATION -->
    <v-dialog v-model="showingRemoveDialog" max-width="300px">
      <v-card>
        <v-card-title class="title">Remove Invitation?</v-card-title>
        <v-card-text>Deleting an invitation is forever. There is no undo.</v-card-text>
        <v-card-actions>
          <v-row class="text-xs-left green lighten-5 pa-2 pb-4">
            <v-btn class="mr-2" color="error" @click="removeInvitation">Delete</v-btn>
            <v-btn class="mr-2" color="success" @click="showingRemoveDialog=false">Cancel</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openFilter } from '@/store/filters'

export default {
  name: 'invitation-dashboard',
  created() {
    window.vm = this
    this.$store.dispatch('clearInvitations')
    this.$store.dispatch('setInvitationFilters', [openFilter])
    this.$store.dispatch('loadInvitations')
  },
  data: () => ({
    showAll: false,
    showingNewDialog: false,
    showingRemoveDialog: false,
    valid: false,
    invitationForm: {},
    invitation: null,
    headers: [
      { text: 'Email', value: 'email' },
      { text: 'Name', value: 'name' },
      { text: 'Expiration', value: 'expiration' },
      { text: 'Permission', value: 'role' },
      { text: 'Link', value: 'link' },
      { text: '', value: 'action', sortable: false }
    ],
    allHeaders: [
      { text: 'Email', value: 'email' },
      { text: 'Name', value: 'name' },
      { text: 'Expiration', value: 'expiration' },
      { text: 'Permission', value: 'role' },
      { text: 'Link', value: 'link' },
      { text: 'Status', value: 'state' },
      { text: '', value: 'action', sortable: false }
    ],
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+/.test(v) || 'Email must be valid'
    ],
    nameRules: [
      v => !!v || 'Name is required'
    ],
    permissionRules: [
      v => !!v || 'Permission is required'
    ]
  }),

  computed: {
    ...mapGetters([
      'invitations',
      'isLoading',
      'canEdit'
    ]),
    invitationPermissionOptions() {
      return this.$store.state.InvitationStore.invitationPermissionOptions
    }
  },

  methods: {
    updateFilters() {
      const filters = []
      if (!this.showAll) {
        filters.push(openFilter)
      }
      this.$store.dispatch('setInvitationFilters', filters)
    },
    openDeleteDialog(item) {
      this.invitation = item
      this.showingRemoveDialog = true
    },

    openNewDialog() {
      this.invitationForm.role = 'EDIT' // set default
      this.showingNewDialog = true
    },

    saveNew() {
      this.showingNewDialog = false
      this.invitationForm.organizationName = this.$store.state.OrganizationStore.organization.name
      this.$store.dispatch('addInvitation', this.invitationForm).then(invitationId => {
        this.$store.dispatch('addAlert', {
          alertName: 'invitationCreated',
          persist: false
        })
        this.$store.dispatch('loadInvitations')
      })
      this.invitationForm = {}
    },

    removeInvitation() {
      this.showingRemoveDialog = false
      this.$store.dispatch('removeInvitation', this.invitation).then(() => {
        this.$store.dispatch('addAlert', {
          alertName: 'invitationRemoved',
          persist: false
        })
        this.$store.dispatch('loadInvitations')
      })
      this.invitation = null
    }

  }
}
</script>

<template>
  <div class="issue-dashboard">
    <h1 class="headline mb-3"><v-icon class="header-icon">mdi-account</v-icon> Personal Dashboard</h1>
    <div class="text-xs-left green  px-2 py-3 lighten-5 d-flex justify-space-between align-center">
      <h5>Tasks</h5>
      <v-btn v-if="canEdit" small color="success" :to="{ name: 'task-new'}">Add Task</v-btn>
    </div>
    <v-data-table
      :headers="taskHeaders"
      :items="tasks"
      item-key="id"
      class="elevation-0"
      :items-per-page="15"
      hide-default-footer
      :loading="isLoading"
      loading-text="Loading... Please Wait"
    >
      <template v-slot:[`item.title`]="{ item }">
        <router-link :to="{ name: 'task-detail', params: { id: item.id }}">{{ item.title }}</router-link>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <span class="status">{{ taskStatusDisplayName(item.state) }}</span>
      </template>
      <template v-slot:[`item.assignedToPersonId`]="{ item }">
        {{ getUserDisplayName(item.assignedToPersonId) }}
      </template>
      <template v-slot:[`item.dueDate`]="{ item }">
        {{ item.dueDate }}
      </template>
      <template v-slot:[`item.createdByPersonId`]="{ item }">
        {{ getUserDisplayName(item.createdByPersonId) }}
      </template>
      <template v-slot:[`item.deploymentId`]="{ item }">
        <router-link v-if="item.deploymentId" :to="{ name: 'deployment-tasks', params: { id: item.deploymentId || 1 }}">{{ getDeploymentDisplayName(item.deploymentId) }}</router-link>
      </template>
    </v-data-table>

    <div class="text-xs-left green mt-5 px-2 py-3 lighten-5 d-flex justify-space-between align-center">
      <h5>Issues</h5>
      <v-btn v-if="canEdit" small color="success" :to="{ name: 'issue-new'}">Add Issue</v-btn>
    </div>
    <v-data-table
      :headers="issueHeaders"
      :items="issues"
      item-key="id"
      class="elevation-0"
      :items-per-page="15"
      hide-default-footer
      :loading="isLoading"
      loading-text="Loading... Please Wait"
    >
      <template v-slot:[`item.title`]="{ item }">
        <router-link :to="{ name: 'issue-detail', params: { id: item.id }}">{{ item.title }}</router-link>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <span class="status">{{ issueStatusDisplayName(item.state) }}</span>
      </template>
      <template v-slot:[`item.assignedToPersonId`]="{ item }">
        {{ getUserDisplayName(item.assignedToPersonId) }}
      </template>
      <template v-slot:[`item.createdByPersonId`]="{ item }">
        {{ getUserDisplayName(item.createdByPersonId) }}
      </template>
      <template v-slot:[`item.deploymentId`]="{ item }">
        <router-link v-if="item.deploymentId" :to="{ name: 'deployment-issues', params: { id: item.deploymentId || 1 }}">{{ getDeploymentDisplayName(item.deploymentId) }}</router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openFilter, assignedToFilter } from '@/store/filters'

export default {
  name: 'issue-dashboard',
  created() {
    window.vm = this
  },
  mounted() {
    this.$store.dispatch('clearTasks')
    this.$store.dispatch('setTaskFilters', [openFilter, assignedToFilter(this.profile.id)])
    this.$store.dispatch('loadTasks')

    this.$store.dispatch('clearIssues')
    this.$store.dispatch('setIssueFilters', [openFilter, assignedToFilter(this.profile.id)])
    this.$store.dispatch('loadIssues')

    this.$store.dispatch('loadDeployments')
    this.$store.dispatch('loadUsers')
  },
  computed: {
    ...mapGetters([
      'taskResolutionDisplayName',
      'taskStatusDisplayName',
      'issueResolutionDisplayName',
      'issueStatusDisplayName',
      'userById',
      'deploymentById',
      'tasks',
      'issues',
      'deployments',
      'users',
      'profile',
      'canEdit',
      'isLoading'
    ])
  },
  data() {
    return {
      showAll: false,
      issueHeaders: [
        { text: 'Title', value: 'title' },
        { text: 'Status', value: 'state' },
        { text: 'Assigned To', value: 'assignedToPersonId' },
        { text: 'Opened By', value: 'createdByPersonId' },
        { text: 'Deployment', value: 'deploymentId' }
      ],
      taskHeaders: [
        { text: 'Title', value: 'title' },
        { text: 'Status', value: 'state' },
        { text: 'Assigned To', value: 'assignedToPersonId' },
        { text: 'Due Date', value: 'dueDate' },
        { text: 'Opened By', value: 'createdByPersonId' },
        { text: 'Deployment', value: 'deploymentId' }
      ]
    }
  },
  methods: {
    getDeploymentDisplayName(deploymentId) {
      const deployment = this.$store.getters.deploymentById(deploymentId)
      return deployment ? deployment.title : ''
    },
    getUserDisplayName(userId) {
      const user = this.$store.getters.userById(userId)
      return user ? `${user.firstName} ${user.lastName}` : ''
    },
    friendlyStatus() {
      let option = null
      if (this.issue) {
        option = this.statusOptions.find(o => o.value === this.issue.state)
      }
      return option ? option.text : ''
    },
    friendlyResolution() {
      // return this.resolutionReasons.find(
      //   r => r.value === this.issueForm.resolution
      // ).text
      return this.issue.resolution
    },
    updateTaskFilters() {
      const filters = []
      if (!this.showAll) {
        filters.push(openFilter)
      }
      this.$store.dispatch('setTaskFilters', filters)
    },
    updateIssueFilters() {
      const filters = []
      if (!this.showAll) {
        filters.push(openFilter)
      }
      this.$store.dispatch('setIssueFilters', filters)
    }
  }
}
</script>

<style>
th {
  white-space: nowrap;
}
.status {
  white-space: nowrap;
  font-size: .8rem;
}
.expanded-row {
  border-bottom: none !important;
}
.expandsection {
  padding: 0.5rem 1.5rem;
  background-color: #efefef;
}
</style>

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:4152db2a-7b8f-4cde-9657-17c43e982841",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_mnXwYa6Hs",
    "aws_user_pools_web_client_id": "3n068rmao4unloouf0vk70cbst",
    "oauth": {}
};


export default awsmobile;

<template>
<div class="mx-auto setup-panel">
  <v-stepper v-model="currentStep" vertical>

    <v-stepper-step :complete="currentStep > 1" step="1">
      Welcome to DeployStatus!
      <small>Begin by creating your personal user account</small>
    </v-stepper-step>
    <v-stepper-content step="1">
      <v-form ref="form" v-model="userAccountCreate.isFormValid" class="mt-1">
        <v-text-field
          outlined
          dense
          label="First Name"
          v-model="userAccountCreate.firstName"
          :rules="[rules.required]"
          required
          autocomplete="fname"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Last Name"
          v-model="userAccountCreate.lastName"
          :rules="[rules.required]"
          required
          autocomplete="lname"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Phone"
          type="tel"
          v-model="userAccountCreate.phone"
          autocomplete="mobile"
        ></v-text-field>
        <v-text-field
          outlined
          v-model="userAccountCreate.email"
          :rules="[rules.required]"
          label="Email"
          type="email"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="userAccountCreate.password"
          :append-icon="userAccountCreate.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          label="Password"
          :type="userAccountCreate.showPassword ? 'text' : 'password'"
          @click:append="userAccountCreate.showPassword = !userAccountCreate.showPassword"
        ></v-text-field>
        <div class="d-flex justify-space-between">
          <v-btn color="primary" @click="createAccount" :disabled="!userAccountCreate.isFormValid">Create Account</v-btn>
          <div>Have code?<v-btn text small @click="currentStep = 2">Confirm Account</v-btn></div>
        </div>
      </v-form>
    </v-stepper-content>

    <v-stepper-step :complete="currentStep > 2" step="2">Confirm user account</v-stepper-step>
    <v-stepper-content step="2">
      <p>A verification code has been sent to your email account. Enter the verification code that was sent.</p>
      <v-form ref="form" v-model="userAccountConfirm.isFormValid" class="mt-1">
        <v-text-field
          outlined
          v-model="userAccountConfirm.email"
          disabled
          :rules="[rules.required]"
          label="Email"
          type="email"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="userAccountConfirm.code"
          :rules="[rules.required]"
          label="Verification Code"
          required
          autocomplete="one-time-code"
        ></v-text-field>
        <v-text-field v-if="!userAccountCreate.password"
          outlined
          v-model="userAccountConfirm.password"
          autocomplete="new-password"
          :append-icon="userAccountConfirm.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          label="Password"
          :type="userAccountConfirm.showPassword ? 'text' : 'password'"
          @click:append="userAccountConfirm.showPassword = !userAccountConfirm.showPassword"
        ></v-text-field>
        <div class="d-flex justify-space-between">
        <v-btn color="primary" @click="confirmAccount" :disabled="!userAccountConfirm.isFormValid">Confirm Account</v-btn>
        <div>Need Code?<v-btn text small @click="currentStep = 1">Register</v-btn><v-btn text small :disabled="!userAccountConfirm.email" @click="resendCode">Resend Code</v-btn></div>
        </div>

      </v-form>
    </v-stepper-content>

    <v-stepper-step :complete="currentStep > 3" step="3">Setup Organization</v-stepper-step>
    <v-stepper-content step="3">
      <p>Next, we need a little information about your organization. You can update this later as needed.</p>
      <v-form ref="form" v-model="organizationAccount.isFormValid" class="mt-1">
        <v-text-field
          outlined
          v-model="organizationAccount.name"
          :rules="[rules.required]"
          label="Organization Name"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="organizationAccount.address.line1"
          :rules="[rules.required]"
          label="Address Line 1"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="organizationAccount.address.line2"
          label="Address Line 2"
        ></v-text-field>
        <v-text-field
          outlined
          v-model="organizationAccount.address.city"
          :rules="[rules.required]"
          label="City"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="organizationAccount.address.state"
          :rules="[rules.required]"
          label="State"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="organizationAccount.address.postal_code"
          :rules="[rules.required]"
          label="Postal Code"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="organizationAccount.address.country"
          :rules="[rules.required]"
          label="Country/Region"
          required
        ></v-text-field>
        <div class="d-flex justify-space-between">
        <v-btn color="primary" @click="setupOrganization" :disabled="!organizationAccount.isFormValid">Setup Organization</v-btn>
        </div>

      </v-form>
    </v-stepper-content>

    <v-stepper-step :complete="currentStep > 4" step="4">
      Select Plan
    </v-stepper-step>
    <v-stepper-content step="4" class="py-2">
      <v-row class="my-1">
        <v-col>
          <v-card>
            <v-card-title>Demo</v-card-title>
            <v-card-subtitle>Try out DeployStatus</v-card-subtitle>
            <v-divider></v-divider>
            <v-list text dense>
              <v-list-item>2 Deployments</v-list-item>
              <v-divider></v-divider>
              <v-list-item>Full Feature Demo</v-list-item>
              <v-divider></v-divider>
              <v-list-item>90 day</v-list-item>
            </v-list>
            <v-card-actions><v-btn class="full-button" @click="selectPlan('demo')">Select Demo Plan</v-btn></v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card color="light-gray">
            <v-card-title>Standard</v-card-title>
            <v-card-subtitle>Typical deployment sizes</v-card-subtitle>
            <v-divider></v-divider>
            <v-list text dense>
              <v-list-item>100 Deployments</v-list-item>
              <v-divider></v-divider>
              <v-list-item>Email Support</v-list-item>
              <v-divider></v-divider>
              <v-list-item>$99/mo or $999/yr</v-list-item>
            </v-list>
            <v-card-actions>
              <stripe-checkout
                ref="checkoutRef"
                mode="subscription"
                :pk="publishableKey"
                :line-items="lineItems"
                :success-url="successURL"
                :cancel-url="cancelURL"
                @loading="v => loading = v"
              />
              <v-btn color="success" class="full-button" @click="selectPlan('standard')">Select Standard</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>Premium</v-card-title>
            <v-card-subtitle>Large deployments</v-card-subtitle>
            <v-divider></v-divider>
            <v-list text dense>
              <v-list-item>1000 Deployments</v-list-item>
              <v-divider></v-divider>
              <v-list-item>Premium Support</v-list-item>
              <v-divider></v-divider>
              <v-list-item>$350/mo or $3500/yr</v-list-item>
            </v-list>
            <v-card-actions><v-btn class="full-button" @click="selectPlan('premium')">Select Premium</v-btn></v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-stepper-content>

  </v-stepper>
  <div>{{ JSON.stringify(userAccountCreate, null, 2) }}</div>
</div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { mapGetters } from 'vuex'
import { StripeCheckout } from '@vue-stripe/vue-stripe'

export default {
  name: 'account-setup',
  components: {
    StripeCheckout
  },
  mounted() {
    // setup some debug helpers
    window.vm = this
    window.Auth = Auth
  },
  data () {
    this.publishableKey = 'pk_test_WVgVRN9vDUrH8Cz4p298hVOK'
    return {
      currentStep: 4,
      standardPriceMonthly: 99,
      isUserInvited: false,
      userAccountCreate: {
        isFormValid: false,
        invitationCode: '',
        email: '',
        password: '',
        showPassword: false,
        firstName: '',
        lastName: '',
        phone: ''
      },
      userAccountConfirm: {
        isFormValid: false,
        email: '',
        password: '',
        code: '',
        showPassword: false
      },
      organizationAccount: {
        isFormValid: false,
        name: '',
        address: {
          city: '',
          country: '',
          line1: '',
          line2: '',
          postal_code: '',
          state: ''
        }
      },
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      },
      loading: false,
      lineItems: [
        {
          price: 'price_1L7Yw3BJzGYUwKLu51p6Xvr7', // The id of the recurring price you created in your Stripe dashboard
          quantity: 1
        }
      ],
      successURL: 'http://localhost:8080/#/',
      cancelURL: 'http://localhost:8080/#/'
    }
  },
  computed: {
    ...mapGetters(['profile']),
    signedIn() {
      return this.$store.state.LoginStore.signedIn
    }
  },
  methods: {
    createAccount() {
      Auth.signUp({
        username: this.userAccountCreate.email,
        password: this.userAccountCreate.password,
        attributes: {
          email: this.userAccountCreate.email
        },
        validationData: [] // optional
      }).then(data => {
        console.log('signUp data', data)
        this.currentStep = 2
      }).catch(err => console.log(err))
    },
    confirmAccount() {
      // After retrieveing the confirmation code from the user
      Auth.confirmSignUp(this.email, this.code, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true
      }).then(data => {
        console.log('confirmSignUp', data)

        Auth.signIn(this.email, this.password)
          .then(user => {
            console.log(user)
            this.$store.dispatch('login', user)
            this.currentStep = 3
          })
          .catch(err => console.log(err))
      }).catch(err => console.log(err))
    },
    resendCode() {
      Auth.resendSignUp(this.email)
    },
    setupOrganization() {

    },
    selectPlan(plan) {
      this.$refs.checkoutRef.redirectToCheckout()
      // if (plan === 'demo') {
      //   this.$router.push('deployments-dashboard')
      // }
      // else {
      //   alert(plan)
      // }
    }
  }
}
</script>

<style scoped>
  .setup-panel {
    max-width: 750px
  }
  .full-button {
    width: 100%;
  }
</style>

<template><div>
  <v-container grid-list-md fluid>
    <DeploymentTabs :id="id"></DeploymentTabs>
    <router-view></router-view>
  </v-container>
</div></template>

<script>
// eslint-disable-next-line
import DeploymentTabs from '@/components/DeploymentTabs.vue'

export default {
  name: 'deployment',
  props: ['id'],
  components: {
    DeploymentTabs
  }
}
</script>

import axios from 'axios'
import _ from 'lodash'

const LOAD_TEMPLATES = 'LOAD_TEMPLATES'
const ADD_TEMPLATE = 'ADD_TEMPLATE'
const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE'
const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE'
const RESET_STATE = 'RESET_STATE'

const baseState = {
  templates: [],
  activeTemplateId: null
}

let state = baseState // eslint-disable-line prefer-const

const getters = {
  templates: state => _.sortBy(state.templates, ['title']),
  activetemplate: state => {
    return state.templates.find(d => d._id === state.activeTemplateId)
  }
}

const mutations = {
  RESET_STATE: (state) => {
    state = baseState
  },
  LOAD_TEMPLATES: (state, templates) => {
    state.templates = templates
  },
  ADD_TEMPLATE: (state, template) => {
    state.templates.push(template)
  },
  UPDATE_TEMPLATE: (state, template) => {
    state.templates = [
      ...state.templates.filter(a => a._id !== template._id),
      template
    ]
    state.activeTemplateId = template._id
  },
  REMOVE_TEMPLATE: (state, templateId) => {
    state.templates = state.templates.filter(a => a._id !== templateId)
  }
}

const actions = {
  loadTemplate: ({ commit, dispatch }, _id) => {
    console.log('called loadtemplate in store with _id:', _id)
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/templates/' + _id)
      .then(result => {
        console.log('server returned template:', result.data)
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_TEMPLATE, result.data)
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  loadTemplates: ({ commit, dispatch }) => {
    console.log('loadtemplates called')
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/templates/')
      .then(result => {
        console.log('data returned from getting templates', result)
        dispatch('setLoadingIndicator', false)
        commit(LOAD_TEMPLATES, result.data)
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  addTemplate: ({ commit, dispatch }, template) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .post('/api/v1/templates/', template)
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(ADD_TEMPLATE, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  updateTemplate: ({ commit, dispatch }, template) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .put('/api/v1/templates/' + template._id, template)
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_TEMPLATE, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  removeTemplate: ({ commit, dispatch }, templateId) => {
    dispatch('setLoadingIndicator', true)
    commit(REMOVE_TEMPLATE, templateId)
    dispatch('setLoadingIndicator', false)
  },
  reset_state: ({ commit }) => {
    console.log('reset_state from within template store')
    commit(RESET_STATE)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

<template>
  <div>
    <h1 class="text-h5 font-weight-light mb-5">About DeployStatus</h1>
    <v-card class="mx-auto" tile>
      <v-list three-line>
        <v-subheader>Upcoming Features</v-subheader>
        <v-list-item v-for="item in roadmapFeatures" :key="item.name">
          <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
            <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'about',
  data() {
    return {
      roadmapFeatures: [
        { name: 'Full Text Search', description: 'Gives the ability to search across all of your deployments for items with specific text' },
        { name: 'Tags', description: 'Add tags to your entities to group everything together in your own way' },
        { name: 'Document Storage', description: 'Attach configuration data, manuals and other day to deployments, equipment, etc.' },
        { name: 'Media', description: 'Link pictures and video to your entities' },
        { name: 'Public Links', description: 'Gives the ability to flag an entity with a publicly available link that anyone can view' },
        { name: 'Data Templates', description: 'Add custom data fields to your entities' },
        { name: 'Notifications', description: 'Subscibe to a deployment, issue or task and get notified when it is updated' },
        { name: 'Email Issue Creation', description: 'Create an issue from a customer email' }
      ]
    }
  }
}
</script>

<style scoped>
  .section-heading {
    letter-spacing: .5rem;
  }
</style>

<template>
  <div>
    <v-breadcrumbs v-if="task && task.deploymentId">
      <v-breadcrumbs-item :to="{ name: 'deployment-dashboard'}">Deployments</v-breadcrumbs-item>
      <v-breadcrumbs-divider><span>|</span></v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{ name: 'deployment-tasks', params: {id: task.deploymentId}}">Tasks</v-breadcrumbs-item>
    </v-breadcrumbs>
    <v-toolbar dense flat>
      <v-icon class="mr-2">mdi-format-list-bulleted-square</v-icon>
      <v-toolbar-title><span v-if="task">{{task.title}}</span></v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- NOTE DIALOG -->
      <v-dialog v-model="noteDialog" persistent max-width="500px" :fullscreen="$vuetify.breakpoint.smAndDown">
        <v-card>
          <v-card-title class="title grey lighten-4">
            <span class="headline"><span v-if="isNoteAddMode">Add</span><span v-if="!isNoteAddMode">Edit</span> Comment</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="Comment"
                  v-model="note"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="green lighten-5">
            <v-btn :disabled="!note.length" color="success" @click.native="saveNote">Save</v-btn>
            <v-btn text @click.native="cancelNote">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="!isNoteAddMode" color="error" text @click="deleteNoteConfirmDialog = !deleteNoteConfirmDialog">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- RESOLVE DIALOG -->
      <v-dialog v-model="resolveDialog" max-width="500px" v-if="!isClosed" :fullscreen="$vuetify.breakpoint.smAndDown">
        <v-card>
          <v-card-title class="title grey lighten-4">
            <span class="headline">Resolve Task</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  outlined
                  dense
                  :items="taskResolutionOptions"
                  label="Resolution Reason"
                  v-model="taskForm.resolution"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  dense
                  label="Resolution Description"
                  v-model="taskForm.resolutionDescription"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="green lighten-5">
            <v-btn color="success" @click.native="saveResolve" :disabled="!taskForm.resolution">Save</v-btn>
            <v-btn text @click.native="cancelResolve">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- EDIT DIALOG -->
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" v-if="task">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click.native="cancel">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Edit Task</v-toolbar-title>

          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" class="mt-3">
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    label="Title"
                    v-model="taskForm.title"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    dense
                    label="Description"
                    v-model="taskForm.description"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    outlined
                    dense
                    :items="users"
                    :item-text="getUserDisplayName"
                    item-value="id"
                    label="Assigned To"
                    v-model="taskForm.assignedToPersonId"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    outlined
                    dense
                    :items="taskStatusOptions"
                    label="Status"
                    v-model="taskForm.state"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="taskForm.state == 'CLOSED'">
                <v-col>
                  <v-select
                    outlined
                    dense
                    :items="taskResolutionOptions"
                    label="Resolution Reason"
                    v-model="taskForm.resolution"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="taskForm.state == 'CLOSED'">
                <v-col>
                  <v-textarea
                    outlined
                    dense
                    label="Resolution Description"
                    v-model="taskForm.resolutionDescription"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    outlined
                    dense
                    :items="deployments"
                    item-text="title"
                    item-value="id"
                    label="Deployment"
                    v-model="taskForm.deploymentId"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="text-xs-left green lighten-5 pa-2">
                <v-btn class="mr-2" color="success" @click.native="save" :disabled="!valid">Save</v-btn>
                <v-btn class="mr-2" text @click.native="cancel">Cancel</v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- CONFIRM NOTE DELETION -->
      <v-dialog v-model="deleteNoteConfirmDialog" max-width="300px">
        <v-card>
          <v-card-title class="title grey lighten-4">Delete Comment?</v-card-title>
          <v-card-text>Deleting a comment is forever. There is no undo.</v-card-text>
          <v-card-actions class="grey lighten-3">
            <v-row class="text-xs-left green lighten-5 pa-2">
              <v-btn class="mr-2" color="error" @click="deleteNote">Delete</v-btn>
              <v-btn class="mr-2" color="success" @click="deleteNoteConfirmDialog=false">Cancel</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-toolbar>

    <!-- MAIN DISPLAY -->
    <v-card v-if="task" flat>
      <v-fab-transition>
        <v-btn
          fab
          small
          color="accent-2"
          top
          right
          absolute
          @click="dialog = !dialog"
          v-if="canEdit"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-fab-transition>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Description</v-list-item-title>
          <p class="multi-line-text">{{ task.description }}</p>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Assigned To</v-list-item-title>
          <v-list-item-subtitle v-if="task.assignedToPersonId">{{getUserDisplayNameFromId(task.assignedToPersonId)}}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="!task.assignedToPersonId">Not assigned</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Status</v-list-item-title>
          <v-list-item-subtitle>{{taskStatusDisplayName(task.state)}} <v-btn x-small depressed rounded color="accessory" @click="resolveDialog = true" v-if="!isClosed && canEdit"><v-icon small>mdi-checkbox-marked-circle-outline</v-icon><span class="px-2">Resolve task</span></v-btn></v-list-item-subtitle>
          <div v-if="isClosed" class="caption">Closed by {{ getUserDisplayNameFromId(task.closedByPersonId) }} | {{ task.closedDate | dayjs("ddd, MMM D, YYYY, h:mm a") }}</div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isClosed">
        <v-list-item-content>
          <v-list-item-title>Resolution</v-list-item-title>
          <v-list-item-subtitle>{{taskResolutionDisplayName(task.resolution)}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isClosed">
        <v-list-item-content>
          <v-list-item-title>Resolution Description</v-list-item-title>
          <p class="multi-line-text">{{ task.resolutionDescription }}</p>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Deployment</v-list-item-title>
          <v-list-item-subtitle v-if="hasDeployment"><router-link :to="{ name: 'deployment-tasks', params: { id: task.deploymentId }}">{{deployment.title}}</router-link></v-list-item-subtitle>
          <v-list-item-subtitle v-if="!hasDeployment">Not assigned</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-4"></v-divider>

      <v-toolbar dense text class="elevation-0">
        <v-toolbar-title>Comments<v-btn icon @click="commentOrderAscending = !commentOrderAscending"><v-icon small>{{ commentOrderIcon }}</v-icon></v-btn></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small depressed @click="openCommentDialog('add')" v-if="canEdit">
          <v-icon>mdi-comment-text-outline</v-icon><span class="px-2">Add Comment</span>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="mb-3" v-for="(item) in sortedNotes" :key="item.id">
          <div class="subheading text--primary">{{item.text}}</div>
          <div class="caption">{{getUserFullNameById(item.createdByPersonId)}} |
            <span v-if="$vuetify.breakpoint.smAndUp">{{ item.createdDate | dayjs("ddd, MMM D, YYYY, h:mm a") }}</span>
            <span v-if="isCommentEdited(item) && $vuetify.breakpoint.smAndUp"> | edited on {{ item.lastUpdatedDate | dayjs("ddd, MMM D, YYYY, h:mm a") }}</span>
            <v-btn v-show="canEditComment(item)" icon @click="openCommentDialog(false, item)">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="task.notes && task.notes.length == 0">
          <div class="subheading text--primary">No comments</div>
        </div>
      </v-card-text>
      <v-divider class="my-4"></v-divider>
      <v-list-item>
        <v-list-item-content>
          <div class="caption">Task opened by {{ getUserDisplayNameFromId(task.createdByPersonId) }} | {{ task.createdDate | dayjs("ddd, MMM D, YYYY, h:mm a") }}</div>
        </v-list-item-content>
      </v-list-item>

    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import { nanoid } from 'nanoid'
import { mapGetters } from 'vuex'
import { openFilter } from '@/store/filters'

export default {
  name: 'task-detail',
  props: ['id'],
  data() {
    return {
      taskForm: {},
      valid: true,
      search: null,
      dialog: false,
      noteDialog: false,
      note: '',
      deleteNoteConfirmDialog: false,
      noteEditId: null,
      isNoteAddMode: true,
      commentOrderAscending: false,
      resolveDialog: false
    }
  },
  created() {
    window.vm = this
    this.$store.dispatch('loadTask', this.id)
    this.$store.dispatch('loadDeployments').then(() => {
      this.$store.dispatch('setDeploymentFilters', [openFilter])
    })
    this.$store.dispatch('loadUsers')
  },
  computed: {
    ...mapGetters([
      'taskResolutionDisplayName',
      'taskStatusDisplayName',
      'canEdit',
      'deploymentById',
      'users',
      'deployments',
      'profile'
    ]),
    taskResolutionOptions() {
      return this.$store.state.TaskStore.taskResolutionOptions
    },
    taskStatusOptions() {
      return this.$store.state.TaskStore.taskStatusOptions
    },
    commentOrderIcon() {
      return this.commentOrderAscending ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
    task() {
      return _.cloneDeep(this.$store.getters.activeTask)
    },
    hasDeployment() {
      return this.task && this.task.deploymentId
    },
    deployment() {
      if (this.task && this.hasDeployment) {
        return this.deploymentById(this.task.deploymentId)
      }
      return null
    },
    sortedNotes() {
      return _.orderBy(this.task.notes, 'lastUpdatedDate', this.commentOrderAscending ? 'asc' : 'desc')
    },
    isClosed() {
      return this.task && this.task.state === 'CLOSED'
    },
    isOpen() {
      return this.task && this.task.state !== 'CLOSED'
    }
  },
  watch: {
    task() {
      this.taskForm = _.cloneDeep(this.$store.getters.activeTask)
    }
  },
  methods: {
    removeTag(item) {
      this.taskForm.tags = [...this.taskForm.tags.filter(t => t !== item)]
    },
    save() {
      this.dialog = false
      if (this.taskForm.state === 'CLOSED' && this.task.state !== 'CLOSED') {
        // user resolved this task. set closedDate
        this.taskForm.closedDate = new Date()
      }
      this.$store
        .dispatch('updateTask', this.taskForm)
        .then(() => {
          this.$store.dispatch('loadTask', this.id)
          this.$store.dispatch('addAlert', {
            alertName: 'taskUpdated',
            persist: false
          })
        })
        .catch(error => {
          this.$store.dispatch('addAlert', 'taskUpdatedFailed', false)
          console.log('unable to update task. error: ', error)
        })
    },
    cancel() {
      this.dialog = false
      this.taskForm = _.cloneDeep(this.$store.getters.activeTask)
    },
    getDeploymentDisplayName(deploymentId) {
      const deployment = this.$store.getters.deploymentById(deploymentId)
      return deployment ? deployment.title : ''
    },
    getUserFullNameById(userId) {
      const user = this.$store.getters.userById(userId)
      return user ? `${user.firstName} ${user.lastName}` : ''
    },
    getUserDisplayName(user) {
      return user ? `${user.firstName} ${user.lastName} (${user.email})` : ''
    },
    getUserDisplayNameFromId(userId) {
      const user = this.$store.getters.userById(userId)
      return user ? `${user.firstName} ${user.lastName} (${user.email})` : ''
    },
    saveNote() {
      this.noteDialog = false

      if (this.isNoteAddMode) {
        const noteData = {
          id: nanoid(10),
          text: this.note,
          createdDate: new Date(),
          createdByPersonId: this.profile.id,
          lastUpdatedDate: new Date(),
          lastUpdatedByPersonId: this.profile.id
        }
        if (!this.taskForm.notes) {
          this.taskForm.notes = []
        }
        this.taskForm.notes.push(noteData)
        this.save()
      }
      else {
        // get original note entry
        const originalNote = this.taskForm.notes.find(n => n.id === this.noteEditId)
        const originalNoteIndex = this.taskForm.notes.findIndex(n => n.id === this.noteEditId)
        if (originalNoteIndex > -1) {
          const noteData = {
            id: originalNote.id,
            text: this.note,
            createdDate: originalNote.createdDate,
            createdByPersonId: originalNote.createdByPersonId,
            lastUpdatedDate: new Date(),
            lastUpdatedByPersonId: this.profile.id
          }
          this.taskForm.notes.splice(originalNoteIndex, 1, noteData)
          this.save()
        }
      }
    },
    cancelNote() {
      this.noteDialog = false
    },
    deleteNote() {
      this.deleteNoteConfirmDialog = false
      this.noteDialog = false

      const originalNoteIndex = this.taskForm.notes.findIndex(n => n.id === this.noteEditId)
      this.taskForm.notes.splice(originalNoteIndex, 1)
      this.save()
    },
    isCommentEdited(note) {
      return note.createdDate !== note.lastUpdatedDate
    },
    canEditComment(note) {
      return note.createdByPersonId === this.profile.id
    },
    openCommentDialog(mode, note) {
      if (mode === 'add') {
        this.noteEditId = null
        this.isNoteAddMode = true
        this.noteDialog = !this.noteDialog
        this.note = ''
      }
      else {
        this.noteEditId = note.id
        this.isNoteAddMode = false
        this.note = note.text
        this.noteDialog = !this.noteDialog
      }
    },
    saveResolve() {
      if (this.profile) {
        this.taskForm.state = 'CLOSED'
        this.taskForm.closedByPersonId = this.profile.id
        this.taskForm.closedDate = new Date()
        this.save()
        this.resolveDialog = false
      }
    },
    cancelResolve() {
      this.resolveDialog = false
    }
  }
}
</script>

<style scoped>
  .multi-line-text {
    font-size: 14px;
    color: rgba(0,0,0,.6);
    line-height: 1.2rem;
  }
</style>

<template>
  <div>
  <h1 class="headline mb-3"><v-icon class="header-icon">mdi-tag-multiple</v-icon> Tags</h1>
  </div>
</template>

<script>
export default {
  name: 'tag-dashboard'
}
</script>

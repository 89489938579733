import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import About from '../views/About.vue'
import Help from '../views/Help.vue'
import Feedback from '../views/Feedback.vue'

import Deployments from '../views/Deployments.vue'
import DeploymentNew from '../views/deployment/DeploymentNew.vue'
import Deployment from '../views/deployment/Deployment.vue'
import DeploymentGeneral from '../views/deployment/General.vue'
import DeploymentEquipment from '../views/deployment/Equipment.vue'
import DeploymentIssues from '../views/deployment/Issues.vue'
import DeploymentTasks from '../views/deployment/Tasks.vue'
import DeploymentDocuments from '../views/deployment/Documents.vue'
import DeploymentMedia from '../views/deployment/Media.vue'
import DeploymentContacts from '../views/deployment/Contacts.vue'

import Contacts from '../views/contacts/Contacts.vue'
import ContactDashboard from '../views/contacts/ContactDashboard.vue'
import Contact from '../views/contacts/Contact.vue'
import ContactNew from '../views/contacts/ContactNew.vue'

import Equipments from '../views/equipment/Equipments.vue'
import EquipmentDashboard from '../views/equipment/EquipmentDashboard.vue'
import Equipment from '../views/equipment/Equipment.vue'
import EquipmentNew from '../views/equipment/EquipmentNew.vue'

import Issues from '../views/issues/Issues.vue'
import IssueDashboard from '../views/issues/IssueDashboard.vue'
import Issue from '../views/issues/Issue.vue'
import IssueNew from '../views/issues/IssueNew.vue'

import Tasks from '../views/tasks/Tasks.vue'
import TaskDashboard from '../views/tasks/TaskDashboard.vue'
import Task from '../views/tasks/Task.vue'
import TaskNew from '../views/tasks/TaskNew.vue'

import Security from '../views/security/Security.vue'
import SecurityUsers from '../views/security/Users.vue'
import SecurityInvitations from '../views/security/Invitations.vue'

import SiteAdmin from '../views/siteadmin/SiteAdmin.vue'
import SiteOrganizations from '../views/siteadmin/Organization.vue'

import Templates from '../views/Templates.vue'
import Billing from '../views/Billing.vue'
import Tags from '../views/Tags.vue'
import UserProfile from '../views/UserProfile.vue'

// account views
import AccountSetup from '../views/account/AccountSetup.vue'
import AccountAccept from '../views/account/AccountAccept.vue'
import PasswordReset from '../views/account/PasswordReset.vue'
import Login from '../views/account/Login.vue'

import store from '../store'
import { Auth } from 'aws-amplify'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: Feedback,
    meta: { requiresAuth: true }
  },
  {
    path: '/deployments',
    name: 'deployment-dashboard',
    component: Deployments,
    meta: { requiresAuth: true }
  },
  {
    path: '/deployment/new',
    name: 'deployment-new',
    component: DeploymentNew,
    meta: { requiresAuth: true }
  },
  {
    path: '/deployment/:id',
    component: Deployment,
    meta: { requiresAuth: true },
    props: true,
    children: [
      {
        path: '',
        name: 'deployment-general',
        component: DeploymentGeneral
      },
      {
        path: 'equipment',
        name: 'deployment-equipments',
        component: DeploymentEquipment
      },
      {
        path: 'issues',
        name: 'deployment-issues',
        component: DeploymentIssues,
        props: true
      },
      {
        path: 'tasks',
        name: 'deployment-tasks',
        component: DeploymentTasks
      },
      {
        path: 'documents',
        name: 'deployment-documents',
        component: DeploymentDocuments
      },
      {
        path: 'media',
        name: 'deployment-media',
        component: DeploymentMedia
      },
      {
        path: 'contacts',
        name: 'deployment-contacts',
        component: DeploymentContacts
      }
    ]
  },
  {
    path: '/issues',
    component: Issues,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'issue-dashboard',
        component: IssueDashboard
      },
      {
        path: 'new',
        name: 'issue-new',
        component: IssueNew
      },
      {
        path: ':id',
        name: 'issue-detail',
        component: Issue,
        props: true
      }
    ]
  },
  {
    path: '/tasks',
    component: Tasks,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'task-dashboard',
        component: TaskDashboard
      },
      {
        path: 'new',
        name: 'task-new',
        component: TaskNew
      },
      {
        path: ':id',
        name: 'task-detail',
        component: Task,
        props: true
      }
    ]
  },
  {
    path: '/equipment',
    component: Equipments,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'equipment-dashboard',
        component: EquipmentDashboard
      },
      {
        path: 'new',
        name: 'equipment-new',
        component: EquipmentNew
      },
      {
        path: ':id',
        name: 'equipment-detail',
        component: Equipment,
        props: true
      }
    ]
  },
  {
    path: '/contacts',
    component: Contacts,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'contact-dashboard',
        component: ContactDashboard
      },
      {
        path: 'new',
        name: 'contact-new',
        component: ContactNew
      },
      {
        path: ':id',
        name: 'contact-detail',
        component: Contact,
        props: true
      }
    ]
  },
  {
    path: '/templates',
    name: 'template-dashboard',
    component: Templates,
    meta: { requiresAuth: true }
  },
  {
    path: '/billing',
    name: 'billing-dashboard',
    component: Billing,
    meta: { requiresAuth: true }
  },
  {
    path: '/security/',
    component: Security,
    meta: { requiresAuth: true },
    props: true,
    children: [
      {
        path: '',
        redirect: { name: 'security-users' }
      },
      {
        path: 'users',
        name: 'security-users',
        component: SecurityUsers
      },
      {
        path: 'invitations',
        name: 'security-invitations',
        component: SecurityInvitations
      }
    ]
  },
  {
    path: '/siteadmin/',
    component: SiteAdmin,
    meta: { requiresAuth: true },
    props: true,
    children: [
      {
        path: '',
        redirect: { name: 'site-organizations' }
      },
      {
        path: 'organizations',
        name: 'site-organizations',
        component: SiteOrganizations
      }
    ]
  },
  {
    path: '/tags',
    name: 'tag-dashboard',
    component: Tags,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'account-login',
    component: Login
  },
  {
    path: '/profile',
    name: 'user-profile',
    component: UserProfile,
    meta: { requiresAuth: true }
  },
  {
    path: '/setup',
    name: 'account-setup',
    component: AccountSetup,
    beforeEnter: (to, from, next) => {
      if (Auth.user) {
        // already has an account, redirect
        next(false)
      }
      else {
        next()
      }
    }
  },
  {
    path: '/accept',
    name: 'account-accept',
    component: AccountAccept,
    beforeEnter: (to, from, next) => {
      if (Auth.user) {
        // already has an account, redirect
        next(false)
      }
      else {
        next()
      }
    }
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('beforeEachRotue change called')
  store.dispatch('clearAlerts')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // route requires authenticated user.
    if (!Auth.user) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
    else {
      next()
    }
  }
  else {
    next()
  }
})

export default router


<template>
<div class="mx-auto setup-panel">
  <v-stepper v-model="currentStep" vertical>

    <v-stepper-step :complete="currentStep > 1" step="1">
      Welcome to DeployStatus!
      <small>Begin by creating your user account</small>
    </v-stepper-step>
    <v-stepper-content step="1">
      <p><span v-if="invitation && invitation.organizationName">You have been invited to join {{ invitation.organizationName }} at DeployStatus. </span>Let's setup your user account.</p>
      <v-form ref="form" v-model="userAccountCreate.isFormValid" class="mt-1">
        <v-text-field
          outlined
          dense
          label="First Name"
          v-model="userAccountCreate.firstName"
          :rules="[rules.required]"
          required
          autocomplete="fname"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Last Name"
          v-model="userAccountCreate.lastName"
          :rules="[rules.required]"
          required
          autocomplete="lname"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Phone"
          type="tel"
          v-model="userAccountCreate.phone"
          autocomplete="mobile"
        ></v-text-field>
        <v-text-field
          outlined
          v-model="userAccountCreate.email"
          :rules="[rules.required]"
          disabled
          label="Email"
          type="email"
          required
          autocomplete="off"
        ></v-text-field>
        <v-text-field
          outlined
          v-model="userAccountCreate.password"
          autocomplete="new-password"
          :append-icon="userAccountCreate.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          label="Password"
          :type="userAccountCreate.showPassword ? 'text' : 'password'"
          @click:append="userAccountCreate.showPassword = !userAccountCreate.showPassword"
        ></v-text-field>
        <div class="d-flex justify-space-between">
          <v-btn color="primary" @click="createAccount" :disabled="!userAccountCreate.isFormValid">Create Account</v-btn>
        </div>
      </v-form>
    </v-stepper-content>

    <v-stepper-step :complete="currentStep > 2" step="2">Confirm user account</v-stepper-step>
    <v-stepper-content step="2">
      <p>A verification code has been sent to your email account. Enter the verification code that was sent.</p>
      <v-form ref="form" v-model="userAccountConfirm.isFormValid" class="mt-1">
        <v-text-field
          outlined
          v-model="userAccountConfirm.email"
          disabled
          :rules="[rules.required]"
          label="Email"
          type="email"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="userAccountConfirm.code"
          :rules="[rules.required]"
          label="Verification Code"
          required
          autocomplete="one-time-code"
        ></v-text-field>
        <v-text-field v-if="!userAccountCreate.password"
          outlined
          v-model="userAccountConfirm.password"
          autocomplete="new-password"
          :append-icon="userAccountConfirm.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          label="Password"
          :type="userAccountConfirm.showPassword ? 'text' : 'password'"
          @click:append="userAccountConfirm.showPassword = !userAccountConfirm.showPassword"
        ></v-text-field>
        <div class="d-flex justify-space-between">
        <v-btn color="primary" @click="confirmAccount" :disabled="!userAccountConfirm.isFormValid">Confirm Account</v-btn>
        <div>Need Code?<v-btn text small @click="currentStep = 1">Register</v-btn><v-btn text small :disabled="!userAccountConfirm.email" @click="resendCode">Resend Code</v-btn></div>
        </div>

      </v-form>
    </v-stepper-content>

  </v-stepper>
  <!-- <div>{{ JSON.stringify(profile, null, 2) }}</div> -->
</div>
</template>

<script>
import { Auth } from 'aws-amplify'
// import { mapGetters } from 'vuex'

export default {
  name: 'account-accept',
  mounted() {
    // setup some debug helpers
    window.vm = this
    window.Auth = Auth
    if (this.$route.query.email && this.$route.query.code) {
      this.isUserInvited = true
      this.userAccountCreate.email = this.$route.query.email
      this.userAccountCreate.invitationCode = this.$route.query.code
      console.log(this.$route.query.email, this.$route.query.code)
      this.$store.dispatch('loadInvitation', { email: this.$route.query.email, code: this.$route.query.code }).then(invitation => {
        this.invitation = invitation

        if (!invitation) {
          this.$router.push('/')
        }
      })
    }
  },
  data () {
    return {
      currentStep: 1,
      isUserInvited: false,
      invitation: null,
      userAccountCreate: {
        isFormValid: false,
        invitationCode: '',
        email: '',
        password: '',
        showPassword: false,
        firstName: '',
        lastName: '',
        phone: ''
      },
      userAccountConfirm: {
        isFormValid: false,
        email: '',
        password: '',
        showPassword: false,
        code: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      }
    }
  },
  computed: {
    // ...mapGetters(['profile']),
    // signedIn() {
    //   return this.$store.state.LoginStore.signedIn
    // }
  },
  watch: {
    'userAccountCreate.email': function(newValue) {
      this.userAccountConfirm.email = newValue
    },
    'userAccountCreate.password': function(newValue) {
      this.userAccountConfirm.password = newValue
    }
  },
  methods: {
    createAccount() {
      Auth.signUp({
        username: this.userAccountCreate.email,
        password: this.userAccountCreate.password,
        attributes: {
          email: this.userAccountCreate.email
        },
        validationData: [] // optional
      }).then(data => {
        console.log('signUp data', data)
        this.currentStep = 2
      }).catch(err => console.log(err))
    },
    confirmAccount() {
      // After retrieveing the confirmation code from the user
      Auth.confirmSignUp(this.userAccountConfirm.email, this.userAccountConfirm.code, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true
      })
        .then(data => {
          console.log('confirmSignUp', data)

          // user accepted and confirmed login account
          // if (data. something == SUCCEEDED or something)
          if (data === 'SUCCESS') {
            Auth.signIn(this.userAccountConfirm.email, this.userAccountConfirm.password)
              .then(async user => {
                console.log(user)
                this.$store.dispatch('login')

                const acceptData = {
                  firstName: this.userAccountCreate.firstName,
                  lastName: this.userAccountCreate.lastName,
                  phone: this.userAccountCreate.phone,
                  email: this.userAccountConfirm.email,
                  code: this.userAccountCreate.invitationCode
                }

                const acceptInvitationResult = await this.$store.dispatch('acceptInvitation', acceptData)
                console.log('acceptInvitationResult', acceptInvitationResult)
                this.$store.dispatch('loadSelf')
                this.$router.push({ name: 'user-profile' })
              })
          }
          else throw new Error('Unable to confirm signup')
        })
        .catch(err => console.log(err))
    },
    resendCode() {
      Auth.resendSignUp(this.userAccountConfirm.email)
    }
  }
}
</script>

<style scoped>
  .setup-panel {
    max-width: 600px
  }
</style>

import axios from 'axios'
import * as util from './util'

const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS'
const LOAD_ORGANIZATION = 'LOAD_ORGANIZATION'
const RESET_STATE = 'RESET_STATE'

const baseState = {
  organizations: [],
  organization: {}
}

let state = baseState // eslint-disable-line prefer-const

const getters = {
  organization: state => state.organization,
  organizations: state => state.organizations
}

const mutations = {
  RESET_STATE: (state) => {
    state = baseState
  },
  LOAD_ORGANIZATIONS: (state, organizations) => {
    state.organizations = organizations
  },
  LOAD_ORGANIZATION: (state, organization) => {
    state.organization = organization
  }
}

const actions = {
  loadOrganizations: async ({ commit, dispatch }) => {
    console.log('loadOrganizations called')
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/organizations/', await util.getAuthorizationHeader())
      .then(result => {
        console.log('data returned from getting organizations', result)
        dispatch('setLoadingIndicator', false)
        const data = result.data ? result.data.map(d => JSON.parse(d.data)) : result.data
        commit(LOAD_ORGANIZATIONS, data)
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  loadOrganization: async ({ commit, dispatch }, id) => {
    console.log('called loadOrganization with id:', id)
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/organizations/' + id, await util.getAuthorizationHeader())
      .then(result => {
        console.log('server returned organization:', result.data)
        dispatch('setLoadingIndicator', false)
        commit(LOAD_ORGANIZATION, JSON.parse(result.data.Item.data))
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  reset_state: ({ commit }) => {
    console.log('reset_state from within organization store')
    commit(RESET_STATE)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

<template>
<div>
  <v-container grid-list-md fluid>
    <div>
      <h1 class="headline mb-3"><v-icon class="header-icon">mdi-format-list-bulleted-square</v-icon>Add Task</h1>
      <v-form v-model="valid">
        <v-row>
          <v-col>
            <v-text-field
              label="Title"
              v-model="taskForm.title"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              label="Description"
              v-model="taskForm.description"
              outlined
              dense
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              ref="menu"
              v-model="datepickermenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="taskForm.dueDate"
                  label="Due Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="taskForm.dueDate" @input="datepickermenu = false" no-title></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="users"
              :item-text="getUserDisplayName"
              item-value="id"
              label="Assigned To"
              v-model="taskForm.assignedToPersonId"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="deployments"
              item-text="title"
              item-value="id"
              label="Deployment"
              v-model="taskForm.deploymentId"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>

        <div class="text-xs-left green lighten-5 pa-2">
          <v-btn class="mr-2" color="success" @click="save(true)" :disabled="!taskForm.title">Save</v-btn>
          <v-btn class="mr-2" color="gray" @click="save(false)" :disabled="!taskForm.title">Save &amp; Add Another</v-btn>
          <v-btn class="mr-2" text @click="cancel">Cancel</v-btn>
        </div>
      </v-form>
    </div>
  </v-container>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openFilter } from '@/store/filters'

export default {
  name: 'task-new',
  data() {
    return {
      taskForm: {},
      valid: true,
      search: null,
      editing: null,
      index: -1,
      datepickermenu: null
    }
  },
  created() {
    window.vm = this
    this.$store.dispatch('loadDeployments').then(() => {
      this.$store.dispatch('setDeploymentFilters', [openFilter])
      this.preAssignDeployment(this.$route.query.deploymentId)
    })
    this.$store.dispatch('loadUsers')
    // this.$store.dispatch('loadTags')
  },
  computed: {
    ...mapGetters([
      'canEdit',
      'deployments',
      'users'
    ])
  },
  methods: {
    preAssignDeployment(deploymentId) {
      if (deploymentId) {
        this.taskForm.deploymentId = deploymentId
      }
    },
    removeTag(item) {
      this.taskForm.tags.splice(this.taskForm.tags.indexOf(item, 1))
    },
    async save(shouldNavigate) {
      try {
        const newTaskId = await this.$store.dispatch('addTask', this.taskForm)
        console.log('save just got a result back from adding the new task:', newTaskId)
        this.$store.dispatch('addAlert', {
          alertName: 'taskCreated',
          persist: true
        })
        if (shouldNavigate) {
          this.$router.push({
            name: 'task-detail',
            params: { id: newTaskId }
          })
        }
        this.taskForm = {}
      }
      catch (error) {
        this.$store.dispatch('addAlert', 'taskCreatedFailed', false)
        console.log('unable to create task. error: ', error)
      }
    },
    cancel() {
      this.taskForm = {}
      this.$router.push({
        name: 'task-dashboard'
      })
    },
    getUserDisplayName(user) {
      console.log('user', user)
      return user ? `${user.firstName} ${user.lastName} (${user.email})` : ''
    },
    edit (index, item) {
      if (!this.editing) {
        this.editing = item
        this.index = index
      }
      else {
        this.editing = null
        this.index = -1
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    }
  }
}
</script>

<template>
  <div>
    <v-container grid-list-md fluid>
      <div>
        <h1 class="headline mb-3"><v-icon class="header-icon">mdi-earth</v-icon>Add Deployments</h1>
        <v-form v-model="valid">
          <v-row>
            <v-col>
              <v-text-field
                label="Title"
                v-model="deploymentForm.title"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                label="Description"
                v-model="deploymentForm.description"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                label="Location"
                v-model="deploymentForm.location"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>

          <div v-if="canEdit" class="text-xs-left green lighten-5 pa-2">
            <v-btn class="mr-2" color="success" @click="save(true)" :disabled="!deploymentForm.title">Save</v-btn>
            <v-btn class="mr-2" color="gray" @click="save(false)" :disabled="!deploymentForm.title">Save &amp; Add Another</v-btn>
            <v-btn class="mr-2" text @click="cancel">Cancel</v-btn>
          </div>

        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'deployment-new',
  data() {
    return {
      deploymentForm: {},
      valid: true,
      search: null
    }
  },
  created() {
    window.vm = this
    //this.$store.dispatch('loadTags')
  },
  computed: {
    ...mapGetters([
      'canEdit',
      'profile'
    ]),
    tagItems() {
      return this.$store.getters.tags(this.search)
    }
  },
  methods: {
    removeTag(item) {
      this.deploymentForm.tags.splice(this.deploymentForm.tags.indexOf(item, 1))
    },
    save(shouldNavigate) {
      this.deploymentForm.createdDate = new Date()
      this.deploymentForm.createdByPersonId = this.profile.id
      this.$store
        .dispatch('addDeployment', this.deploymentForm)
        .then(deploymentId => {
          this.$store.dispatch('addAlert', {
            alertName: 'deploymentCreated',
            persist: true
          })
          if (shouldNavigate) {
            this.$router.push({
              name: 'deployment-general',
              params: { id: deploymentId }
            })
          }
          this.deploymentForm = {}
        })
        .catch(error => {
          this.$store.dispatch('addAlert', {
            alertName: 'deploymentCreatedFailed',
            persist: false
          })
          console.log('unable to create deployment. error: ', error)
        })
    },
    cancel() {
      this.deploymentForm = {}
      this.$router.push({
        name: 'deployment-dashboard'
      })
    }

  }
}
</script>
<style scoped="true">
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"headline mb-3"},[_c('v-icon',{staticClass:"header-icon"},[_vm._v("mdi-earth")]),_vm._v(" Deployments")],1),_c('div',{staticClass:"text-xs-left green px-2 py-3 lighten-5 d-flex flex-row-reverse justify-space-between align-center"},[(_vm.hasClosedDeployments)?_c('v-checkbox',{attrs:{"label":"Include Archived Deployments","dense":""},on:{"click":function($event){return _vm.updateFilters()}},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}}):_vm._e(),(_vm.canEdit)?_c('v-btn',{attrs:{"small":"","color":"success","to":{ name: 'deployment-new'}}},[_vm._v("Add Deployment")]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.showAll ? _vm.allHeaders : _vm.headers,"items":_vm.deployments,"hide-default-footer":"","loading":_vm.isLoading,"loading-text":"Loading... Please Wait"},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'deployment-general', params: { id: item.id }}}},[_vm._v(_vm._s(item.title))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"status"},[_vm._v(_vm._s(_vm.deploymentStatusDisplayName(item.state)))])]}},{key:"item.issuesCount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"to":{ name: 'deployment-issues', params: { id: item.id }},"color":item.issuesCount > 0 ? 'error' : 'success',"flat":"","small":""}},[_vm._v(_vm._s(item.issuesCount ? item.issuesCount : 0))])]}},{key:"item.tasksCount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"to":{ name: 'deployment-tasks', params: { id: item.id }},"color":item.tasksCount > 0 ? 'error' : 'success',"flat":"","small":""}},[_vm._v(_vm._s(item.tasksCount ? item.tasksCount : 0))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
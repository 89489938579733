import axios from 'axios'
import _ from 'lodash'
import * as util from './util'

const LOAD_INVITATIONS = 'LOAD_INVITATIONS'
const LOAD_INVITATION = 'LOAD_INVITATION'
const ADD_INVITATION = 'ADD_INVITATION'
const SET_INVITATION_FILTERS = 'SET_INVITATION_FILTERS'
const RESET_STATE = 'RESET_STATE'

const baseState = {
  invitation: {},
  invitations: [],
  invitationFilters: [],
  invitationPermissionOptions: [
    { text: 'Administrator', value: 'ADMIN' },
    { text: 'Edit', value: 'EDIT' },
    { text: 'View', value: 'VIEW' }
  ]
}

let state = baseState // eslint-disable-line prefer-const

const getters = {
  invitations: state => {
    let items = state.invitations
    for (let index = 0; index < state.invitationFilters.length; index++) {
      const f = state.invitationFilters[index]
      items = f(items)
    }
    return _.sortBy(items, ['title'])
  }
}

const mutations = {
  RESET_STATE: (state) => {
    state = baseState
  },
  SET_INVITATION_FILTERS: (state, filterFunctions) => {
    state.invitationFilters = filterFunctions
  },
  LOAD_INVITATIONS: (state, invitations) => {
    state.invitations = invitations
  },
  LOAD_INVITATION: (state, invitation) => {
    state.invitation = invitation
  },
  ADD_INVITATION: (state, invitation) => {
    state.invitations.push(invitation)
  }
}

const actions = {
  clearInvitations: ({ commit }) => commit(LOAD_INVITATIONS, []),
  setInvitationFilters: ({ commit }, filterFunctions) => {
    commit(SET_INVITATION_FILTERS, filterFunctions)
  },
  loadInvitations: async ({ commit, dispatch }) => {
    console.log('loadInvitations called')
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/invitations/', await util.getAuthorizationHeader())
      .then(result => {
        console.log('data returned from getting invitations', result)
        dispatch('setLoadingIndicator', false)
        const data = result.data ? result.data.map(d => JSON.parse(d.data)) : result.data
        commit(LOAD_INVITATIONS, data)
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  loadInvitation: async ({ commit, dispatch }, data) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .get(`/api/v1/invitations/accept/${data.email}/${data.code}`)
      .then(result => {
        console.log('invitation: ', result)
        dispatch('setLoadingIndicator', false)
        const data = JSON.parse(result.data[0].data)
        commit(LOAD_INVITATION, data)
        return data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  addInvitation: async ({ commit, dispatch }, invitation) => {
    console.log('add invitation:', invitation)
    dispatch('setLoadingIndicator', true)
    return axios
      .post('/api/v1/invitations/', invitation, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(ADD_INVITATION, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  removeInvitation: async({ dispatch }, invitation) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .post('/api/v1/invitations/remove', invitation, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
        throw error
      })
  },
  reset_state: ({ commit }) => {
    console.log('reset_state from within invitation store')
    commit(RESET_STATE)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

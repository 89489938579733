<template><div>
  <h1 v-if="deployment" class="headline mb-3"><v-icon class="header-icon">mdi-earth</v-icon> {{ deployment.title }}</h1>
  <v-tabs class="mb-4">
    <v-tab :to="{name: 'deployment-general'}">General</v-tab>
    <v-tab :to="{name: 'deployment-equipments'}">Equipment</v-tab>
    <v-tab :to="{name: 'deployment-issues'}">Issues</v-tab>
    <v-tab :to="{name: 'deployment-tasks'}">Tasks</v-tab>
    <!-- <v-tab :to="{name: 'deployment-documents'}">Documents</v-tab>
    <v-tab :to="{name: 'deployment-media'}">Media</v-tab> -->
    <v-tab :to="{name: 'deployment-contacts'}">Contacts</v-tab>
  </v-tabs>
  <!-- <div v-if="deployment" class="caption mt-5">Last updated: {{ deployment.updatedAt }}</div> -->
</div></template>

<script>
export default {
  name: 'DeploymentTabs',
  props: ['id'],
  computed: {
    deployment() {
      return this.$store.getters.activeDeployment
    }
  },
  created() {
    this.$store.dispatch('loadDeployment', this.id)
  }
}
</script>

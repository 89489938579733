<template>
<v-app>
  <v-navigation-drawer app clipped v-model="drawer" v-if="signedIn">

    <v-list dense nav>
      <v-list-item link :to="{ name: 'deployment-dashboard'}">
        <v-list-item-icon>
          <v-icon>mdi-earth</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Deployments</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :to="{ name: 'equipment-dashboard'}">
        <v-list-item-icon>
          <v-icon>mdi-server</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Equipment</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :to="{ name: 'issue-dashboard'}">
        <v-list-item-icon>
          <v-icon>mdi-bug</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Issues</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :to="{ name: 'task-dashboard'}">
        <v-list-item-icon>
          <v-icon>mdi-format-list-bulleted-square</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Tasks</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :to="{ name: 'contact-dashboard'}">
        <v-list-item-icon>
          <v-icon>mdi-contacts</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Contacts</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item link :to="{ name: 'tag-dashboard'}">
        <v-list-item-icon>
          <v-icon>mdi-tag-multiple</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Tags</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

    </v-list>

    <v-divider v-if="isAdmin"></v-divider>

    <v-list dense nav v-if="isAdmin">
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-key-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item-content>
        </template>

        <!-- <v-list-item :to="{ name: 'template-dashboard'}">
          <v-list-item-icon>
            <v-icon>mdi-content-duplicate</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Templates</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item link :to="{ name: 'security-users'}">
          <v-list-item-icon>
            <v-icon>mdi-security</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Security</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'billing-dashboard'}">
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Billing</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>
    </v-list>

    <v-divider v-if="isSiteAdmin"></v-divider>
    <v-list dense nav v-if="isSiteAdmin">
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-key-chain</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Site Admin</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item link :to="{ name: 'site-organizations'}">
          <v-list-item-icon>
            <v-icon>mdi-home-group</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Organizations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>
    </v-list>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item link :to="{ name: 'feedback'}">
        <v-list-item-icon>
          <v-icon>mdi-message-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Send Feedback</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item link :to="{ name: 'help'}">
        <v-list-item-icon>
          <v-icon>mdi-help</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Help</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

    </v-list>

  </v-navigation-drawer>

  <v-app-bar app clipped-left flat dense color="blue-grey darken-4 white--text lighten-2">
    <v-app-bar-nav-icon v-if="signedIn" @click.stop="drawer = !drawer" class="grey--text"></v-app-bar-nav-icon>
    <v-toolbar-title>
      <router-link to="/" class="v-toolbar__title site-title" custom v-slot="{ navigate }">
        <div @click="navigate">DeployStatus</div>
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <span class="organization-title pr-4" v-if="signedIn">{{organization.name}}</span>
    <v-btn class="login-btn" :to="{ name: 'account-login' }" v-if="!signedIn">
      <v-icon>mdi-account</v-icon> Sign In
    </v-btn>
    <v-btn class="profile-btn" :to="{ name: 'user-profile' }" v-if="signedIn && profileName">
      <v-icon>mdi-account</v-icon><span v-if="$vuetify.breakpoint.smAndUp">{{profileName}}</span>
    </v-btn>
  </v-app-bar>

  <!-- Sizes your content based upon application components -->
  <v-main>

    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <alert-manager/>
      <router-view :key="$route.fullPath"></router-view>
    </v-container>
  </v-main>

  <v-footer app>
    <v-spacer></v-spacer>
    <div><v-btn text :to="{name: 'about'}">About</v-btn></div>
  </v-footer>
</v-app>
</template>

<script>
import AlertManager from '@/components/AlertManager.vue'
// import { components, AmplifyEventBus } from 'aws-amplify-vue'
import { components } from 'aws-amplify-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'app',
  components: {
    AlertManager,
    ...components
  },
  mounted() {
    this.$store.dispatch('isUserAuthenticated').then(authenticated => {
      if (authenticated) {
        this.$store.dispatch('loadSelf').then(user => {
          this.$store.dispatch('loadOrganization', user.organizationId)
        })
      }
    })
  },
  data: () => ({
    drawer: null
  }),
  computed: {
    ...mapGetters(['profile', 'organization', 'signedIn']),
    isAdmin() {
      return this.profile && ['SITE_ADMIN', 'ADMIN'].includes(this.profile.role)
    },
    isSiteAdmin() {
      return this.profile && ['SITE_ADMIN'].includes(this.profile.role)
    },
    profileName() {
      return this.$store.getters.fullName
    }
  },
  methods: {}
}
</script>

<style>
td a {
  text-decoration: none;
}
.v-btn {
  text-transform: none;
  font-family: 'Jura', sans-serif;
  letter-spacing: normal;
}

.login-btn, .profile-btn {
  box-shadow: none;
}

.site-title, .organization-title {
  font-family: 'Jura', sans-serif;
  cursor: pointer;
}

.progress-bar {
  margin: 1px 0;
}

.v-datatable a {
  text-decoration: none;
}

.header-icon {
  vertical-align: baseline;
  margin-right: 2px;
}

</style>

import axios from 'axios'
import _ from 'lodash'
import * as util from './util'

const LOAD_TASKS = 'LOAD_TASKS'
const ADD_TASK = 'ADD_TASK'
const UPDATE_TASK = 'UPDATE_TASK'
const SET_TASK_FILTERS = 'SET_TASK_FILTERS'
const RESET_STATE = 'RESET_STATE'

const baseState = {
  tasks: [],
  taskFilters: [],
  activeTaskId: null,
  taskResolutionOptions: [
    { text: 'Task Abandoned', value: 'ABANDON' },
    { text: 'Task Completed', value: 'COMPLETE' }
  ],
  taskStatusOptions: [
    { text: 'Open / New', value: 'OPEN_NEW' },
    { text: 'Open / Assigned', value: 'OPEN_ASSIGNED' },
    { text: 'Open / Blocked', value: 'OPEN_BLOCKED' },
    { text: 'Closed', value: 'CLOSED' }
  ]
}

let state = baseState // eslint-disable-line prefer-const

const getters = {
  tasks: state => {
    let items = state.tasks
    for (let index = 0; index < state.taskFilters.length; index++) {
      const f = state.taskFilters[index]
      items = f(items)
    }
    return _.sortBy(items, ['title'])
  },
  activeTask: state => {
    return state.tasks.find(d => d.id === state.activeTaskId)
  },
  taskById: state => id => state.tasks.find(d => d.id === id),
  taskResolutionDisplayName: state => value => {
    const option = state.taskResolutionOptions.find(o => o.value === value)
    return option ? option.text : ''
  },
  taskStatusDisplayName: state => value => {
    const option = state.taskStatusOptions.find(o => o.value === value)
    return option ? option.text : ''
  }
}

const mutations = {
  RESET_STATE: (state) => {
    state = baseState
  },
  SET_TASK_FILTERS: (state, filterFunctions) => {
    state.taskFilters = filterFunctions
  },
  LOAD_TASKS: (state, tasks) => {
    state.tasks = tasks
  },
  ADD_TASK: (state, task) => {
    state.tasks.push(task)
  },
  UPDATE_TASK: (state, task) => {
    state.tasks = [...state.tasks.filter(a => a.id !== task.id), task]
    state.activeTaskId = task.id
  },
  REMOVE_TASK: (state, taskId) => {
    state.tasks = state.tasks.filter(a => a.id !== taskId)
  }
}

const actions = {
  clearTasks: ({ commit }) => commit(LOAD_TASKS, []),
  setTaskFilters: ({ commit }, filterFunctions) => {
    commit(SET_TASK_FILTERS, filterFunctions)
  },
  loadTask: async ({ commit, dispatch }, id) => {
    console.log('called loadTask in store with id:', id)
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/tasks/' + id, await util.getAuthorizationHeader())
      .then(result => {
        console.log('server returned task:', result.data)
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_TASK, JSON.parse(result.data.Item.data))
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  loadTasks: async ({ commit, dispatch }, deploymentId) => {
    console.log('loadTasks called with deploymentId:', deploymentId)
    dispatch('setLoadingIndicator', true)
    return axios
      .get(deploymentId ? `/api/v1/tasks/deployment/${deploymentId}` : '/api/v1/tasks/', await util.getAuthorizationHeader())
      .then(result => {
        console.log('data returned from getting tasks', result)
        dispatch('setLoadingIndicator', false)
        const data = result.data ? result.data.map(d => JSON.parse(d.data)) : result.data
        console.log('processed task data:', data)
        commit(LOAD_TASKS, data)
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  addTask: async ({ commit, dispatch }, task) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .post('/api/v1/tasks/', task, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(ADD_TASK, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  updateTask: async ({ commit, dispatch }, task) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .put('/api/v1/tasks/' + task.id, task, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_TASK, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  reset_state: ({ commit }) => {
    console.log('reset_state from within task store')
    commit(RESET_STATE)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

import axios from 'axios'
import _ from 'lodash'
import * as util from './util'

const LOAD_ISSUES = 'LOAD_ISSUES'
const ADD_ISSUE = 'ADD_ISSUE'
const UPDATE_ISSUE = 'UPDATE_ISSUE'
const SET_ISSUE_FILTERS = 'SET_ISSUE_FILTERS'
const RESET_STATE = 'RESET_STATE'

const baseState = {
  issues: [],
  issueFilters: [],
  activeIssueId: null,
  issueResolutionOptions: [
    { text: 'Will not fix', value: 'WONT_FIX' },
    { text: 'Could not reproduce issue', value: 'NO_REPRO' },
    { text: 'Fixed', value: 'FIXED' },
    { text: 'External issue', value: 'EXTERNAL' }
  ],
  issueStatusOptions: [
    { text: 'Open / New', value: 'OPEN_NEW' },
    { text: 'Open / Assigned', value: 'OPEN_ASSIGNED' },
    { text: 'Open / Blocked', value: 'OPEN_BLOCKED' },
    { text: 'Closed', value: 'CLOSED' }
  ]
}

let state = baseState // eslint-disable-line prefer-const

const getters = {
  issues: state => {
    let items = state.issues
    for (let index = 0; index < state.issueFilters.length; index++) {
      const f = state.issueFilters[index]
      items = f(items)
    }
    return _.sortBy(items, ['title'])
  },
  activeIssue: state => {
    return state.issues.find(d => d.id === state.activeIssueId)
  },
  issueById: state => id => {
    return state.issues.find(d => d.id === id)
  },
  issueResolutionDisplayName: state => value => {
    const option = state.issueResolutionOptions.find(o => o.value === value)
    return option ? option.text : ''
  },
  issueStatusDisplayName: state => value => {
    const option = state.issueStatusOptions.find(o => o.value === value)
    return option ? option.text : ''
  }
}

const mutations = {
  RESET_STATE: (state) => {
    state = baseState
  },
  SET_ISSUE_FILTERS: (state, filterFunctions) => {
    state.issueFilters = filterFunctions
  },
  LOAD_ISSUES: (state, issues) => {
    state.issues = issues
  },
  ADD_ISSUE: (state, issue) => {
    state.issues.push(issue)
  },
  UPDATE_ISSUE: (state, issue) => {
    state.issues = [...state.issues.filter(a => a.id !== issue.id), issue]
    state.activeIssueId = issue.id
  },
  REMOVE_ISSUE: (state, issueId) => {
    state.issues = state.issues.filter(a => a.id !== issueId)
  }
}

const actions = {
  clearIssues: ({ commit }) => commit(LOAD_ISSUES, []),
  setIssueFilters: ({ commit }, filterFunctions) => {
    commit(SET_ISSUE_FILTERS, filterFunctions)
  },
  loadIssue: async ({ commit, dispatch }, id) => {
    console.log('called loadIssue in store with id:', id)
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/issues/' + id, await util.getAuthorizationHeader())
      .then(result => {
        console.log('server returned issue:', result.data)
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_ISSUE, JSON.parse(result.data.Item.data))
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  loadIssues: async ({ commit, dispatch }, deploymentId) => {
    console.log('loadIssues called with deploymentId:', deploymentId)
    dispatch('setLoadingIndicator', true)
    return axios
      .get(deploymentId ? `/api/v1/issues/deployment/${deploymentId}` : '/api/v1/issues/', await util.getAuthorizationHeader())
      .then(result => {
        window.console.log('data returned from getting issues', result)
        dispatch('setLoadingIndicator', false)
        const data = result.data ? result.data.map(d => JSON.parse(d.data)) : result.data
        commit(LOAD_ISSUES, data)
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        window.console.log(error)
      })
  },
  addIssue: async ({ commit, dispatch }, issue) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .post('/api/v1/issues/', issue, await util.getAuthorizationHeader())
      .then(result => {
        window.console.log('added Issue. now dispatching and retrunign it:', result)
        dispatch('setLoadingIndicator', false)
        commit(ADD_ISSUE, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  updateIssue: async ({ commit, dispatch }, issue) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .put('/api/v1/issues/' + issue.id, issue, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_ISSUE, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  reset_state: ({ commit }) => {
    console.log('reset_state from within issue store')
    commit(RESET_STATE)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

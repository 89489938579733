<template>
  <div class="contact-dashboard">
    <div class="text-xs-left green lighten-5 d-flex justify-space-between align-center">
      <v-checkbox class="text-body-2 mx-2" v-model="showAll" label="Include Archived Contacts" dense @click="updateFilters()"></v-checkbox>
      <v-btn v-if="canEdit" class="ma-2" small color="success" :to="{ name: 'contact-new', query: { deploymentId: this.$route.params.id}}">Add Contact</v-btn>
    </div>
    <v-data-table
      :headers="showAll ? allHeaders : headers"
      :items="contacts"
      item-key="id"
      class="elevation-0"
      :items-per-page="15"
      hide-default-footer
      :loading="isLoading"
      loading-text="Loading... Please Wait"
    >
      <template v-slot:[`item.firstName`]="{ item }">
        <router-link :to="{ name: 'contact-detail', params: { id: item.id }}">{{ item.firstName }}</router-link>
      </template>
      <template v-slot:[`item.lastName`]="{ item }">
        <router-link :to="{ name: 'contact-detail', params: { id: item.id }}">{{ item.lastName }}</router-link>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <span class="status">{{ contactStatusDisplayName(item.state) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openFilter, deploymentFilter } from '@/store/filters'

export default {
  name: 'deployment-contacts',
  created() {
    window.vm = this
    this.$store.dispatch('clearContacts')
    this.$store.dispatch('setContactFilters', [openFilter, deploymentFilter(this.$route.params.id)])
    this.$store.dispatch('loadContacts')
    this.$store.dispatch('loadDeployments', this.$route.params.id) // pass deploymentId to limit query scope
    this.$store.dispatch('loadUsers')
  },
  computed: {
    ...mapGetters([
      'contactStatusDisplayName',
      'userById',
      'deploymentById',
      'contacts',
      'deployments',
      'users',
      'canEdit',
      'isLoading'
    ])
  },
  data() {
    return {
      showAll: false,
      headers: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' }
      ],
      allHeaders: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Status', value: 'state' }
      ]
    }
  },
  methods: {
    getDeploymentDisplayName(deploymentId) {
      const deployment = this.$store.getters.deploymentById(deploymentId)
      return deployment ? deployment.title : ''
    },
    getUserDisplayName(userId) {
      const user = this.$store.getters.userById(userId)
      return user ? `${user.firstName} ${user.lastName}` : ''
    },
    friendlyStatus() {
      let option = null
      if (this.contact) {
        option = this.statusOptions.find(o => o.value === this.contact.state)
      }
      return option ? option.text : ''
    },
    updateFilters() {
      const filters = [deploymentFilter(this.$route.params.id)]
      if (!this.showAll) {
        filters.push(openFilter)
      }
      this.$store.dispatch('setContactFilters', filters)
    }
  }
}
</script>

<style>
th {
  white-space: nowrap;
}
.status {
  white-space: nowrap;
  font-size: .8rem;
}
.expanded-row {
  border-bottom: none !important;
}
.expandsection {
  padding: 0.5rem 1.5rem;
  background-color: #efefef;
}
</style>

import axios from 'axios'
import _ from 'lodash'
import * as util from './util'

const LOAD_EQUIPMENTS = 'LOAD_EQUIPMENTS'
const ADD_EQUIPMENT = 'ADD_EQUIPMENT'
const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT'
const SET_EQUIPMENT_FILTERS = 'SET_EQUIPMENT_FILTERS'
const RESET_STATE = 'RESET_STATE'

const baseState = {
  equipments: [],
  setEquipmentFilters: [],
  activeEquipmentId: null,
  equipmentStatusOptions: [
    { text: 'Active', value: 'OPEN' },
    { text: 'Archived', value: 'CLOSED' }
  ]
}

let state = baseState // eslint-disable-line prefer-const

const getters = {
  equipments: state => {
    let items = state.equipments
    for (let index = 0; index < state.setEquipmentFilters.length; index++) {
      const f = state.setEquipmentFilters[index]
      items = f(items)
    }
    return _.sortBy(items, ['title'])
  },
  activeEquipment: state => {
    return state.equipments.find(d => d.id === state.activeEquipmentId)
  },
  equipmentById: state => id => state.equipments.find(d => d.id === id),
  equipmentStatusDisplayName: state => value => {
    const option = state.equipmentStatusOptions.find(o => o.value === value)
    return option ? option.text : ''
  }
}

const mutations = {
  RESET_STATE: (state) => {
    state = baseState
  },
  SET_EQUIPMENT_FILTERS: (state, filterFunctions) => {
    state.setEquipmentFilters = filterFunctions
  },
  LOAD_EQUIPMENTS: (state, equipments) => {
    state.equipments = equipments
  },
  ADD_EQUIPMENT: (state, equipment) => {
    state.equipments.push(equipment)
  },
  UPDATE_EQUIPMENT: (state, equipment) => {
    state.equipments = [
      ...state.equipments.filter(a => a.id !== equipment.id),
      equipment
    ]
    state.activeEquipmentId = equipment.id
  },
  REMOVE_EQUIPMENT: (state, equipmentId) => {
    state.equipments = state.equipments.filter(a => a.id !== equipmentId)
  }
}

const actions = {
  clearEquipments: ({ commit }) => commit(LOAD_EQUIPMENTS, []),
  setEquipmentFilters: ({ commit }, filterFunctions) => {
    commit(SET_EQUIPMENT_FILTERS, filterFunctions)
  },
  loadEquipment: async ({ commit, dispatch }, id) => {
    console.log('called loadEquipment in store with id:', id)
    dispatch('setLoadingIndicator', true)
    return axios
      .get('/api/v1/equipments/' + id, await util.getAuthorizationHeader())
      .then(result => {
        console.log('server returned equipment:', result.data)
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_EQUIPMENT, JSON.parse(result.data.Item.data))
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  loadEquipments: async ({ commit, dispatch }, deploymentId) => {
    console.log('loadEquipments called with deploymentId:', deploymentId)
    dispatch('setLoadingIndicator', true)
    return axios
      .get(deploymentId ? `/api/v1/equipments/deployment/${deploymentId}` : '/api/v1/equipments/', await util.getAuthorizationHeader())
      .then(result => {
        console.log('data returned from getting equipment', result)
        dispatch('setLoadingIndicator', false)
        const data = result.data ? result.data.map(d => JSON.parse(d.data)) : result.data
        commit(LOAD_EQUIPMENTS, data)
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  addEquipment: async ({ commit, dispatch }, equipment) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .post('/api/v1/equipments/', equipment, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(ADD_EQUIPMENT, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  updateEquipment: async ({ commit, dispatch }, equipment) => {
    dispatch('setLoadingIndicator', true)
    return axios
      .put('/api/v1/equipments/' + equipment.id, equipment, await util.getAuthorizationHeader())
      .then(result => {
        dispatch('setLoadingIndicator', false)
        commit(UPDATE_EQUIPMENT, result.data)
        return result.data
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
      })
  },
  reset_state: ({ commit }) => {
    console.log('reset_state from within equipment store')
    commit(RESET_STATE)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

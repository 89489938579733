const ADD_ALERT = 'ADD_ALERT'
const CLEAR_ALERTS = 'CLEAR_ALERTS'
const DISMISS_ALERT = 'DISMISS_ALERT'
const LOG_ERROR = 'LOG_ERROR'

const state = {
  alerts: [
    {
      name: 'profileUpdated',
      text: 'The profile was updated',
      type: 'success',
      count: 0
    },
    {
      name: 'profileUpdatedFailed',
      text: 'The profile could not be updated',
      type: 'error',
      count: 0
    },
    {
      name: 'deploymentCreated',
      text: 'The new deployment was created',
      type: 'success',
      count: 0
    },
    {
      name: 'deploymentCreatedFailed',
      text: 'The new deployment could not be created',
      type: 'error',
      count: 0
    },
    {
      name: 'deploymentUpdated',
      text: 'The deployment was updated',
      type: 'success',
      count: 0
    },
    {
      name: 'deploymentUpdatedFailed',
      text: 'The deployment could not be updated',
      type: 'error',
      count: 0
    },
    {
      name: 'deploymentRemoved',
      text: 'The deployment was removed',
      type: 'success',
      count: 0
    },
    {
      name: 'issueCreated',
      text: 'The new issue was created',
      type: 'success',
      count: 0
    },
    {
      name: 'issueCreatedFailed',
      text: 'The new issue could not be created',
      type: 'error',
      count: 0
    },
    {
      name: 'issueUpdated',
      text: 'The issue was updated',
      type: 'success',
      count: 0
    },
    {
      name: 'issueUpdatedFailed',
      text: 'The issue could not be updated',
      type: 'error',
      count: 0
    },
    {
      name: 'issueRemoved',
      text: 'The issue was removed',
      type: 'success',
      count: 0
    },
    {
      name: 'taskCreated',
      text: 'The new task was created',
      type: 'success',
      count: 0
    },
    {
      name: 'taskCreatedFailed',
      text: 'The new task could not be created',
      type: 'error',
      count: 0
    },
    {
      name: 'taskUpdated',
      text: 'The task was updated',
      type: 'success',
      count: 0
    },
    {
      name: 'taskUpdatedFailed',
      text: 'The task could not be updated',
      type: 'error',
      count: 0
    },
    {
      name: 'taskRemoved',
      text: 'The task was removed',
      type: 'success',
      count: 0
    },
    {
      name: 'contactCreated',
      text: 'The new contact was created',
      type: 'success',
      count: 0
    },
    {
      name: 'contactCreatedFailed',
      text: 'The new contact could not be created',
      type: 'error',
      count: 0
    },
    {
      name: 'contactUpdated',
      text: 'The contact was updated',
      type: 'success',
      count: 0
    },
    {
      name: 'contactUpdatedFailed',
      text: 'The contact could not be updated',
      type: 'error',
      count: 0
    },
    {
      name: 'contactRemoved',
      text: 'The contact was removed',
      type: 'success',
      count: 0
    },
    {
      name: 'equipmentCreated',
      text: 'The new equipment was created',
      type: 'success',
      count: 0
    },
    {
      name: 'equipmentCreatedFailed',
      text: 'The new equipment could not be created',
      type: 'error',
      count: 0
    },
    {
      name: 'equipmentUpdated',
      text: 'The equipment was updated',
      type: 'success',
      count: 0
    },
    {
      name: 'equipmentUpdatedFailed',
      text: 'The equipment could not be updated',
      type: 'error',
      count: 0
    },
    {
      name: 'equipmentRemoved',
      text: 'The equipment was removed',
      type: 'success',
      count: 0
    },
    {
      name: 'invitationCreated',
      text: 'The new invitation was created',
      type: 'success',
      count: 0
    },
    {
      name: 'invitationCreatedFailed',
      text: 'The new invitation could not be created',
      type: 'error',
      count: 0
    },
    {
      name: 'invitationUpdated',
      text: 'The invitation was updated',
      type: 'success',
      count: 0
    },
    {
      name: 'invitationUpdatedFailed',
      text: 'The invitation could not be updated',
      type: 'error',
      count: 0
    },
    {
      name: 'invitationRemoved',
      text: 'The invitation was removed',
      type: 'success',
      count: 0
    },
    {
      name: 'feedbackSent',
      text:
        'Thank you for sending feedback. Your comments are very much appreciated.',
      type: 'success',
      count: 0
    },
    {
      name: 'feedbackSentFailed',
      text: `Ugh... something went wrong sending the feedback. Please try to get this to us
        another way as your comments are very much appreciated.`,
      type: 'error',
      count: 0
    }
  ]
}

const getters = {
  alerts: state => state.alerts.filter(a => a.count > 0)
}

const mutations = {
  ADD_ALERT: (state, { alertName, count }) => {
    const alert = state.alerts.find(a => a.name === alertName)
    alert.count = count
  },
  CLEAR_ALERTS: state => {
    state.alerts.forEach(a => {
      if (a.count > 0) {
        a.count = a.count - 1
      }
    })
  },
  DISMISS_ALERT: (state, alertName) => {
    const alert = state.alerts.find(a => a.name === alertName)
    if (alert.count > 0) {
      alert.count = alert.count - 1
    }
  },
  LOG_ERROR: (state, error) => {
    // TODO add a better process for logging development errors
    console.log(error)
  }
}

const actions = {
  addAlert: ({ commit, state }, { alertName, persist }) => {
    console.log('alertName', alertName, 'persist', persist)
    const alert = state.alerts.find(a => a.name === alertName)
    console.log('alert', alert)
    if (!alert) {
      // this is a development error and should not happen at runtime
      commit(LOG_ERROR, alertName + ' is not a valid alert name')
      return
    }
    commit(ADD_ALERT, { alertName: alertName, count: persist ? 2 : 1 })
  },
  clearAlerts: ({ commit }) => {
    commit(CLEAR_ALERTS)
  },
  dismissAlert: ({ commit }, alertName) => {
    commit(DISMISS_ALERT, alertName)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

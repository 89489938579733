<template><div>
  <v-container grid-list-md fluid>
    <div>
      <h1 class="headline mb-3"><v-icon class="header-icon">mdi-message-outline</v-icon> Feedback</h1>
      <v-form v-model="valid">
        <v-layout row wrap>
          <p>We want Deploy Status to be absolutely perfect. Please send us your comments on features you like, things that could work even better as well as any areas we could improve. Your comments are really appreciated and we'll use this to help us plan future releases. Thanks!</p>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-textarea
              outlined
              label="Your Message"
              v-model="feedbackForm.message"
              required
            ></v-textarea>
          </v-flex>
        </v-layout>
        <div class="text-xs-left green lighten-5 pa-2">
          <v-btn color="success" @click="save" :disabled="!feedbackForm.message">Send</v-btn>
        </div>
      </v-form>
    </div>
  </v-container>
</div></template>

<script>
export default {
  name: 'feedback',
  data() {
    return {
      feedbackForm: {},
      valid: false
    }
  },
  created() {
    window.vm = this
  },
  methods: {
    save() {
      this.$store
        .dispatch('sendFeedback', this.feedbackForm)
        .then(() => {
          this.$store.dispatch('addAlert', {
            alertName: 'feedbackSent',
            persist: false
          })
          this.feedbackForm = {}
        })
        .catch(() => {
          this.$store.dispatch('addAlert', {
            alertName: 'feedbackSentFailed',
            persist: false
          })
        })
    }
  }
}
</script>

<template><div>
  <h1 class="headline mb-3"><v-icon class="header-icon">mdi-security</v-icon> Security</h1>
  <v-tabs class="mb-4">
    <v-tab :to="{name: 'security-users'}">Users</v-tab>
    <v-tab :to="{name: 'security-invitations'}">Invitations</v-tab>
  </v-tabs>
</div></template>

<script>
export default {
  name: 'SecurityTabs'
}
</script>

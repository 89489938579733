<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      item-key="id"
      class="elevation-0"
      hide-default-footer
      :loading="isLoading"
      loading-text="Loading... Please Wait"
    >
      <template v-slot:[`item.role`]="{ item }">
        {{ roleDisplayName(item.role) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'security-users',
  created() {
    window.vm = this
    this.$store.dispatch('loadUsers')
  },
  computed: {
    ...mapGetters([
      'users',
      'isLoading',
      'roleDisplayName'
    ])
  },
  data() {
    return {
      showAll: false,
      headers: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Permission', value: 'role' }
      ]
    }
  }
}
</script>

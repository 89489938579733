import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import dayjs from 'dayjs'

import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

Vue.use(AmplifyPlugin, AmplifyModules)

Vue.config.productionTip = false

Vue.filter('dayjs', function (value, formatString) {
  if (!value) return ''
  const d = dayjs(value)
  return d.format(formatString)
})

window.console.log('Stay safe kids. https://xkcd.com/327/')

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

<template>
<div>
  <v-container grid-list-md fluid>
    <div>
      <h1 class="headline mb-3"><v-icon class="header-icon">mdi-bug</v-icon>Add Issue</h1>
      <v-form v-model="valid">
        <v-row>
          <v-col>
            <v-text-field
              label="Title"
              v-model="issueForm.title"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              label="Description"
              v-model="issueForm.description"
              outlined
              dense
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="users"
              :item-text="getUserDisplayName"
              item-value="id"
              label="Assigned To"
              v-model="issueForm.assignedToPersonId"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="deployments"
              item-text="title"
              item-value="id"
              label="Deployment"
              v-model="issueForm.deploymentId"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>

        <div class="text-xs-left green lighten-5 pa-2">
          <v-btn class="mr-2" color="success" @click="save(true)" :disabled="!issueForm.title">Save</v-btn>
          <v-btn class="mr-2" color="gray" @click="save(false)" :disabled="!issueForm.title">Save &amp; Add Another</v-btn>
          <v-btn class="mr-2" text @click="cancel">Cancel</v-btn>
        </div>
      </v-form>
    </div>
  </v-container>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openFilter } from '@/store/filters'

export default {
  name: 'issue-new',
  data() {
    return {
      issueForm: {},
      valid: true,
      search: null,
      editing: null,
      index: -1
    }
  },
  created() {
    window.vm = this
    this.$store.dispatch('loadDeployments').then(() => {
      this.$store.dispatch('setDeploymentFilters', [openFilter])
      this.preAssignDeployment(this.$route.query.deploymentId)
    })
    this.$store.dispatch('loadUsers')
    // this.$store.dispatch('loadTags')
  },
  computed: {
    ...mapGetters([
      'canEdit',
      'deployments',
      'users'
    ])
  },
  methods: {
    preAssignDeployment(deploymentId) {
      if (deploymentId) {
        this.issueForm.deploymentId = deploymentId
      }
    },
    removeTag(item) {
      this.issueForm.tags.splice(this.issueForm.tags.indexOf(item, 1))
    },
    async save(shouldNavigate) {
      try {
        const newIssueId = await this.$store.dispatch('addIssue', this.issueForm)
        console.log('save just got a result back from adding the new issue:', newIssueId)
        this.$store.dispatch('addAlert', {
          alertName: 'issueCreated',
          persist: true
        })
        if (shouldNavigate) {
          this.$router.push({
            name: 'issue-detail',
            params: { id: newIssueId }
          })
        }
        this.issueForm = {}
      }
      catch (error) {
        this.$store.dispatch('addAlert', 'issueCreatedFailed', false)
        console.log('unable to create issue. error: ', error)
      }
    },
    cancel() {
      this.issueForm = {}
      this.$router.push({
        name: 'issue-dashboard'
      })
    },
    getUserDisplayName(user) {
      console.log('user', user)
      return user ? `${user.firstName} ${user.lastName} (${user.email})` : ''
    },
    edit (index, item) {
      if (!this.editing) {
        this.editing = item
        this.index = index
      }
      else {
        this.editing = null
        this.index = -1
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    }
  }
}
</script>

<template>
  <div class="organization-dashboard">
    <h1 class="headline mb-3"><v-icon class="header-icon">mdi-home-group</v-icon> Organizations</h1>
    <v-data-table
      :headers="headers"
      :items="organizations"
      item-key="id"
      class="elevation-0"
      :items-per-page="15"
      hide-default-footer
      :loading="isLoading"
      loading-text="Loading... Please Wait"
    >
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'site-organizations',
  created() {
    window.vm = this
    this.$store.dispatch('loadOrganizations')
  },
  computed: {
    ...mapGetters([
      'organizations',
      'isLoading'
    ])
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Phone', value: 'phone' },
        { text: 'Stripe Id', value: 'stripeId' },
        { text: 'Tier', value: 'tier' }
      ]
    }
  },
  methods: {
  }
}
</script>

<style>
.expanded-row {
  border-bottom: none !important;
}
.expandsection {
  padding: 0.5rem 1.5rem;
  background-color: #efefef;
}
</style>

<template><div>
  <v-card
    class="mx-auto"
    max-width="500"
    outlined
  >
    <v-card-title>
      <v-icon large left>mdi-account</v-icon>
      <span class="title font-weight-light">Sign In</span>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-text-field
          outlined
          v-model="email"
          :rules="[rules.required]"
          label="Email"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          label="Password"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-btn color="primary" @click="signIn" :disabled="!valid">Sign In</v-btn>
      </v-form>
      <v-divider class="my-3"></v-divider>
      <div class="pr-3">Forget your password?<router-link :to="{name: 'password-reset'}"> <span class="pl-3 body-2 font-weight-light">Reset password</span></router-link></div>
      <div class="pr-3">No account?<router-link :to="{name: 'account-setup'}"> <span class="pl-3 body-2 font-weight-light">Create account</span></router-link></div>
    </v-card-text>

  </v-card>
</div>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  name: 'account-login',
  created() {
  },
  data () {
    return {
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      },
      showPassword: false,
      email: '',
      password: '',
      valid: false
    }
  },
  computed: {
    signedIn() {
      return this.$store.state.LoginStore.signedIn
    }
  },
  methods: {
    signIn() {
      Auth.signIn(this.email, this.password)
        .then(user => {
          this.$store.dispatch('login')
          this.$store.dispatch('loadSelf')
          if (this.$route.query.redirect) {
            this.$router.push({ path: this.$route.query.redirect })
          }
          else {
            this.$router.push({ name: 'deployment-dashboard' })
          }
        })
        .catch(err => console.log(err))
    }
  }
}
</script>

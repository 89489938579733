var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-dashboard"},[_c('h1',{staticClass:"headline mb-3"},[_c('v-icon',{staticClass:"header-icon"},[_vm._v("mdi-contacts")]),_vm._v(" Contact Dashboard")],1),_c('div',{staticClass:"text-xs-left green lighten-5 d-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"text-body-2 mx-2",attrs:{"label":"Include Archived Contacts","dense":""},on:{"click":function($event){return _vm.updateFilters()}},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}}),(_vm.canEdit)?_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","color":"success","to":{ name: 'contact-new'}}},[_vm._v("Add Contact")]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.showAll ? _vm.allHeaders : _vm.headers,"items":_vm.contacts,"item-key":"id","items-per-page":15,"hide-default-footer":"","loading":_vm.isLoading,"loading-text":"Loading... Please Wait"},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'contact-detail', params: { id: item.id }}}},[_vm._v(_vm._s(item.firstName))])]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'contact-detail', params: { id: item.id }}}},[_vm._v(_vm._s(item.lastName))])]}},{key:"item.deploymentId",fn:function(ref){
var item = ref.item;
return [(item.deploymentId)?_c('router-link',{attrs:{"to":{ name: 'deployment-contacts', params: { id: item.deploymentId || 1 }}}},[_vm._v(_vm._s(_vm.getDeploymentDisplayName(item.deploymentId)))]):_vm._e()]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"status"},[_vm._v(_vm._s(_vm.contactStatusDisplayName(item.state)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
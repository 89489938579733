import Vue from 'vue'
import Vuex from 'vuex'

import AlertManagerStore from './AlertManagerStore'
import LoadingIndicatorStore from './LoadingIndicatorStore'
import ContactStore from './ContactStore'
import DeploymentStore from './DeploymentStore'
import IssueStore from './IssueStore'
import TaskStore from './TaskStore'
import TemplateStore from './TemplateStore'
import TagStore from './TagStore'
import FeedbackStore from './FeedbackStore'
import EquipmentStore from './EquipmentStore'
import LoginStore from './LoginStore'
import UserStore from './UserStore'
import InvitationStore from './InvitationStore'
import OrganizationStore from './OrganizationStore'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    AlertManagerStore,
    LoadingIndicatorStore,
    ContactStore,
    DeploymentStore,
    IssueStore,
    TaskStore,
    TemplateStore,
    TagStore,
    FeedbackStore,
    EquipmentStore,
    LoginStore,
    UserStore,
    InvitationStore,
    OrganizationStore
  },
  strict: process.env.NODE_ENV !== 'production'
})

export default store

//add named export for ease of unit testing
export const mutations = store._mutations

<template>
  <div class="task-dashboard">
    <div class="text-xs-left green lighten-5 d-flex justify-space-between align-center">
      <v-checkbox class="text-body-2 mx-2" v-model="showAll" label="Include Closed Tasks" dense @click="updateFilters()"></v-checkbox>
      <v-btn v-if="canEdit" class="ma-2" small color="success" :to="{ name: 'task-new', query: { deploymentId: this.$route.params.id}}">Add Task</v-btn>
    </div>
    <v-data-table
      :headers="showAll ? allHeaders : headers"
      :items="tasks"
      item-key="id"
      class="elevation-0"
      :items-per-page="15"
      hide-default-footer
      :loading="isLoading"
      loading-text="Loading... Please Wait"
    >
      <template v-slot:[`item.title`]="{ item }">
        <router-link :to="{ name: 'task-detail', params: { id: item.id }}">{{ item.title }}</router-link>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <span class="status">{{ taskStatusDisplayName(item.state) }}</span>
      </template>
      <template v-show="showAll" v-slot:[`item.resolution`]="{ item }">
        {{ taskResolutionDisplayName(item.resolution) }}
      </template>
      <template v-slot:[`item.assignedToPersonId`]="{ item }">
        {{ getUserDisplayName(item.assignedToPersonId) }}
      </template>
      <template v-slot:[`item.dueDate`]="{ item }">
        {{ item.dueDate }}
      </template>
      <template v-slot:[`item.createdByPersonId`]="{ item }">
        {{ getUserDisplayName(item.createdByPersonId) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openFilter, deploymentFilter } from '@/store/filters'

export default {
  name: 'deployment-tasks',
  created() {
    window.vm = this
    this.$store.dispatch('clearTasks')
    this.$store.dispatch('setTaskFilters', [openFilter, deploymentFilter(this.$route.params.id)])
    this.$store.dispatch('loadTasks', this.$route.params.id) // pass deploymentId to limit query scope
    this.$store.dispatch('loadDeployments')
    this.$store.dispatch('loadUsers')
  },
  computed: {
    ...mapGetters([
      'taskResolutionDisplayName',
      'taskStatusDisplayName',
      'userById',
      'deploymentById',
      'tasks',
      'deployments',
      'users',
      'canEdit',
      'isLoading'
    ])
  },
  data() {
    return {
      showAll: false,
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Status', value: 'state' },
        { text: 'Assigned To', value: 'assignedToPersonId' },
        { text: 'Due Date', value: 'dueDate' },
        { text: 'Opened By', value: 'createdByPersonId' }
      ],
      allHeaders: [
        { text: 'Title', value: 'title' },
        { text: 'Status', value: 'state' },
        { text: 'Resolution', value: 'resolution' },
        { text: 'Assigned To', value: 'assignedToPersonId' },
        { text: 'Due Date', value: 'dueDate' },
        { text: 'Opened By', value: 'createdByPersonId' }
      ]
    }
  },
  methods: {
    getDeploymentDisplayName(deploymentId) {
      const deployment = this.$store.getters.deploymentById(deploymentId)
      return deployment ? deployment.title : ''
    },
    getUserDisplayName(userId) {
      const user = this.$store.getters.userById(userId)
      return user ? `${user.firstName} ${user.lastName}` : ''
    },
    updateFilters() {
      const filters = [deploymentFilter(this.$route.params.id)]
      if (!this.showAll) {
        filters.push(openFilter)
      }
      this.$store.dispatch('setTaskFilters', filters)
    }
  }
}
</script>

<style>
th {
  white-space: nowrap;
}
.status {
  white-space: nowrap;
  font-size: .8rem;
}
.expanded-row {
  border-bottom: none !important;
}
.expandsection {
  padding: 0.5rem 1.5rem;
  background-color: #efefef;
}
</style>

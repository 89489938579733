var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"issue-dashboard"},[_c('h1',{staticClass:"headline mb-3"},[_c('v-icon',{staticClass:"header-icon"},[_vm._v("mdi-bug")]),_vm._v(" Issue Dashboard")],1),_c('div',{staticClass:"text-xs-left green lighten-5 d-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"text-body-2 mx-2",attrs:{"label":"Include Closed Issues","dense":""},on:{"click":function($event){return _vm.updateFilters()}},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}}),(_vm.canEdit)?_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","color":"success","to":{ name: 'issue-new'}}},[_vm._v("Add Issue")]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.showAll ? _vm.allHeaders : _vm.headers,"items":_vm.issues,"item-key":"id","items-per-page":15,"hide-default-footer":"","loading":_vm.isLoading,"loading-text":"Loading... Please Wait"},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'issue-detail', params: { id: item.id }}}},[_vm._v(_vm._s(item.title))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"status"},[_vm._v(_vm._s(_vm.issueStatusDisplayName(item.state)))])]}},{key:"item.resolution",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.issueResolutionDisplayName(item.resolution))+" ")]}},{key:"item.assignedToPersonId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserDisplayName(item.assignedToPersonId))+" ")]}},{key:"item.createdByPersonId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserDisplayName(item.createdByPersonId))+" ")]}},{key:"item.deploymentId",fn:function(ref){
var item = ref.item;
return [(item.deploymentId)?_c('router-link',{attrs:{"to":{ name: 'deployment-issues', params: { id: item.deploymentId || 1 }}}},[_vm._v(_vm._s(_vm.getDeploymentDisplayName(item.deploymentId)))]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-alert
      class="mb-3"
      v-for="(a,i) in alerts"
      :key="i"
      :type="a.type"
      :value="true"
      dismissible
      outlined
      @click="dismissAlert(a.name)"
      >{{a.text}}</v-alert>
  </div>
</template>

<script>
export default {
  name: 'alert-manager',
  data() {
    return {
      alert: true
    }
  },
  computed: {
    alerts() {
      return this.$store.getters.alerts
    }
  },
  methods: {
    dismissAlert(alertName) {
      this.$store.dispatch('dismissAlert', alertName)
    }
  }
}
</script>

import * as util from './util'
import axios from 'axios'
import { Auth } from 'aws-amplify'

const RESET_STATE = 'RESET_STATE'

const baseState = {
  profile: {},
  signedIn: false,
  PERMISSION: {
    SITE_ADMIN: 'SITE_ADMIN',
    ADMIN: 'ADMIN',
    EDIT: 'EDIT',
    VIEW: 'VIEW'
  },
  roles: [
    { value: 'SITE_ADMIN', text: 'Site Administrator' },
    { value: 'ADMIN', text: 'Administrator' },
    { value: 'EDIT', text: 'Edit' },
    { value: 'VIEW', text: 'View' }
  ]
}

let state = baseState // eslint-disable-line prefer-const

const getters = {
  fullName: () => state.profile ? `${state.profile.firstName} ${state.profile.lastName}` : '',
  fullNameWithEmail: () => state.profile ? `${state.profile.firstName} ${state.profile.lastName} (${state.profile.email})` : '',
  profile: () => state.profile,
  signedIn: () => state.signedIn,
  canEdit: (state, getters) => {
    return getters.hasPermission(state.PERMISSION.EDIT)
  },
  hasPermission: (state, getters) => permission => {
    const role = state.profile ? state.profile.role : null
    if (!role) {
      return false
    }

    let rolesWithPermission = null

    switch (permission) {
      case state.PERMISSION.SITE_ADMIN:
        rolesWithPermission = [state.PERMISSION.SITE_ADMIN]
        break
      case state.PERMISSION.ADMIN:
        rolesWithPermission = [state.PERMISSION.SITE_ADMIN, state.PERMISSION.ADMIN]
        break
      case state.PERMISSION.EDIT:
        rolesWithPermission = [state.PERMISSION.SITE_ADMIN, state.PERMISSION.ADMIN, state.PERMISSION.EDIT]
        break
      case state.PERMISSION.VIEW:
        rolesWithPermission = [state.PERMISSION.SITE_ADMIN, state.PERMISSION.ADMIN, state.PERMISSION.EDIT, state.PERMISSION.VIEW]
        break
    }
    return rolesWithPermission && rolesWithPermission.includes(permission)
  },
  roleDisplayName: state => value => {
    const option = state.roles.find(o => o.value === value)
    return option ? option.text : ''
  }
}

const actions = {
  loadSelf: async ({ commit, dispatch }) => {
    let user = null
    window.console.log('loadSelf called')
    try {
      user = await Auth.currentAuthenticatedUser()
      window.console.log('user:', user)
      if (user.attributes.email_verified) {
        try {
          dispatch('login', user)
          return axios
            .get('/api/v1/users/self', await util.getAuthorizationHeader())
            .then(result => {
              window.console.log('profile:', result)
              commit('profile', JSON.parse(result.data.Item.data))
              return JSON.parse(result.data.Item.data)
            })
        }
        catch (err) {
          window.console.log('Error getting user profile:', err)
        }
      }
    }
    catch (err) {
      console.log(err)
    }
  },
  isUserAuthenticated: async () => {
    console.log('isUserAuthenticated called: ', Auth)
    if (Auth.user) {
      const user = await Auth.currentAuthenticatedUser()
      console.log('user:', user)
      return user.attributes.email_verified
    }
    return false
  },
  login({ commit, dispatch }, user) {
    commit('login', user)
  },
  logout({ commit, dispatch }) {
    dispatch('reset_state')
    commit('logout')
  },
  updateProfile: async ({ commit, dispatch }, profile) => {
    window.console.log('updating profile:', profile)
    return axios
      .put('/api/v1/users/' + profile.id, profile, await util.getAuthorizationHeader())
      .then(result => {
        console.log('got result from profile update:', result)
        return dispatch('loadSelf')
      })
      .catch(error => {
        dispatch('setLoadingIndicator', false)
        console.log(error)
        throw error
      })
  },
  reset_state: ({ commit }) => {
    console.log('reset_state from within login store')
    commit(RESET_STATE)
  }
}

const mutations = {
  RESET_STATE: (state) => {
    state = baseState
  },
  logout(state) {
    state.user = null
    state.signedIn = false
    state.profile = null
  },
  login(state, user) {
    state.user = user
    state.signedIn = true
  },
  profile(state, profile) {
    state.profile = profile
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

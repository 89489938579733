<template><div>
  <v-toolbar dense flat>
    <v-icon>mdi-account</v-icon>
    <v-toolbar-title><span>{{fullName}}</span></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" v-if="profile">
      <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click.native="cancel">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Edit Profile</v-toolbar-title>
          </v-toolbar>
        <v-card-text>
          <v-form v-model="valid" class="pt-5">
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    label="First Name"
                    v-model="profileForm.firstName"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    label="Last Name"
                    v-model="profileForm.lastName"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    label="Phone"
                    v-model="profileForm.phone"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    label="Email"
                    disabled
                    v-model="profileForm.email"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="text-xs-left green lighten-5 pa-2">
                <v-btn class="mr-2" color="success" @click.native="save" :disabled="!valid">Save</v-btn>
                <v-btn class="mr-2" text @click.native="cancel">Cancel</v-btn>
              </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-toolbar>

  <v-card v-if="profile" flat>
    <v-fab-transition>
      <v-btn
        fab
        small
        color="accent-2"
        top
        right
        absolute
        @click="dialog = !dialog"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-card-text>
      <div class="info-group" v-if="organization">
        <h5 class="subheading">Organization</h5>
        <p>{{organization.name}}</p>
      </div>
      <div class="info-group">
        <h5 class="subheading">First Name</h5>
        <p>{{profile.firstName}}</p>
      </div>
      <div>
        <h5 class="subheading">Last Name</h5>
        <p>{{profile.lastName}}</p>
      </div>
      <div>
        <h5 class="subheading">Phone</h5>
        <p>{{profile.phone}}</p>
      </div>
      <div>
        <h5 class="subheading">Email</h5>
        <p>{{profile.email}}</p>
      </div>
      <div>
        <h5 class="subheading">Role</h5>
        <p>{{friendlyRole}}</p>
      </div>
    </v-card-text>
  </v-card>

  <v-container grid-list-md fluid>
    <div>
      <v-btn color="primary" @click="signOut">Sign Out</v-btn>
    </div>
  </v-container>

</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Auth } from 'aws-amplify'
import _ from 'lodash'

export default {
  name: 'user-profile',
  mounted() {
    this.$store.dispatch('loadSelf').then(user => {
      this.$store.dispatch('loadOrganization')
    })
  },
  created() {
  },
  data() {
    return {
      profileForm: {},
      valid: true,
      dialog: false
    }
  },
  computed: {
    ...mapGetters([
      'fullName',
      'signedIn',
      'profile',
      'organization'
    ]),
    profile() {
      return _.cloneDeep(this.$store.getters.profile)
    },
    friendlyRole() {
      const profile = this.$store.getters.profile
      switch (profile.role) {
        case 'SITE_ADMIN':
          return 'Site Administrator'
        case 'ADMIN':
          return 'Administrator'
        case 'EDIT':
          return 'Editor'
        case 'VIEW':
          return 'Viewer'
        default:
          return 'Unknown'
      }
    }
  },
  watch: {
    profile() {
      this.profileForm = _.cloneDeep(this.$store.getters.profile)
    }
  },

  methods: {
    signOut() {
      Auth.signOut()
        .then(data => {
          this.$store.dispatch('logout')
          this.$router.push({ name: 'account-login' })
        })
        .catch(err => console.log(err))
    },
    save() {
      this.dialog = false
      this.$store
        .dispatch('updateProfile', this.profileForm)
        .then(() => {
          this.$store.dispatch('addAlert', {
            alertName: 'profileUpdated',
            persist: false
          })
        })
        .catch(err => {
          window.console.log(err)
          this.$store.dispatch('addAlert', 'profileUpdatedFailed', false)
        })
    },
    cancel() {
      this.dialog = false
      this.profileForm = _.cloneDeep(this.$store.getters.profile)
    }
  }
}
</script>

const UPDATE_LOADING_INDICATOR = 'UPDATE_LOADING_INDICATOR'

const state = {
  isLoading: false
}

const getters = {
  isLoading: state => state.isLoading
}

const actions = {
  setLoadingIndicator: ({ commit }, enableLoadingIndicator) => {
    //enableLoadingIndicator == true to turn on indicator. false to turn off
    commit(UPDATE_LOADING_INDICATOR, enableLoadingIndicator)
  }
}

const mutations = {
  UPDATE_LOADING_INDICATOR: (state, enableLoadingIndicator) => {
    state.isLoading = enableLoadingIndicator
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

<template>
  <div>
    <h1 class="headline mb-3"><v-icon class="header-icon">mdi-earth</v-icon> Deployments</h1>
    <div class="text-xs-left green px-2 py-3 lighten-5 d-flex flex-row-reverse justify-space-between align-center">
      <v-checkbox v-if="hasClosedDeployments" v-model="showAll" label="Include Archived Deployments" dense @click="updateFilters()"></v-checkbox>
      <v-btn v-if="canEdit" small color="success" :to="{ name: 'deployment-new'}">Add Deployment</v-btn>
    </div>
    <v-data-table
      :headers="showAll ? allHeaders : headers"
      :items="deployments"
      class="elevation-0"
      hide-default-footer
      :loading="isLoading"
      loading-text="Loading... Please Wait"
      >
      <template v-slot:[`item.title`]="{ item }">
        <router-link :to="{ name: 'deployment-general', params: { id: item.id }}">{{ item.title }}</router-link>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <span class="status">{{ deploymentStatusDisplayName(item.state) }}</span>
      </template>
      <template v-slot:[`item.issuesCount`]="{ item }">
        <v-chip :to="{ name: 'deployment-issues', params: { id: item.id }}" :color="item.issuesCount > 0 ? 'error' : 'success'" flat small>{{ item.issuesCount ? item.issuesCount : 0 }}</v-chip>
      </template>
      <template v-slot:[`item.tasksCount`]="{ item }">
        <v-chip :to="{ name: 'deployment-tasks', params: { id: item.id }}" :color="item.tasksCount > 0 ? 'error' : 'success'" flat small>{{ item.tasksCount ? item.tasksCount : 0 }}</v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openFilter } from '@/store/filters'

export default {
  name: 'deployment-dashboard',
  created() {
    window.vm = this
    this.$store.dispatch('clearDeployments')
    this.$store.dispatch('loadDeployments').then(
      this.$store.dispatch('setDeploymentFilters', [openFilter])
    )
  },
  computed: {
    ...mapGetters([
      'deployments',
      'canEdit',
      'isLoading',
      'deploymentStatusDisplayName',
      'hasClosedDeployments'
    ])
  },
  data() {
    return {
      showAll: false,
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Issues', value: 'issuesCount', align: 'center' },
        { text: 'Tasks', value: 'tasksCount', align: 'center' }
      ],
      allHeaders: [
        { text: 'Title', value: 'title' },
        { text: 'Status', value: 'state' },
        { text: 'Issues', value: 'issuesCount', align: 'center' },
        { text: 'Tasks', value: 'tasksCount', align: 'center' }
      ]
    }
  },
  methods: {
    updateFilters() {
      const filters = []
      if (!this.showAll) {
        filters.push(openFilter)
      }
      this.$store.dispatch('setDeploymentFilters', filters)
    }
  }
}
</script>

import axios from 'axios'
import _ from 'lodash'
import * as util from './util'

const LOAD_TAGS = 'LOAD_TAGS'
const RESET_STATE = 'RESET_STATE'

const baseState = {
  tags: []
}

let state = baseState // eslint-disable-line prefer-const

const getters = {
  tags: state => searchWord => {
    if (searchWord) {
      return _.sortBy(
        state.tags.filter(t =>
          t.toLowerCase().includes(searchWord.toLowerCase())
        )
      )
    }
    else {
      return state.tags
    }
  }
}

const mutations = {
  RESET_STATE: (state) => {
    state = baseState
  },
  LOAD_TAGS: (state, tags) => {
    state.tags = tags
  }
}

const actions = {
  loadTags: async ({ commit }) => {
    console.log('loadingTags')
    return axios
      .get('/api/v1/tags', await util.getAuthorizationHeader())
      .then(result => {
        const data = result.data.Item.data
        console.log('server returned tags:', data)
        commit(LOAD_TAGS, JSON.parse(data))
      })
      .catch(error => {
        console.log(error)
      })
  },
  reset_state: ({ commit }) => {
    console.log('reset_state from within tag store')
    commit(RESET_STATE)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

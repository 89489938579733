<template>
  <div>
  <h1 class="headline mb-3"><v-icon class="header-icon">mdi-help</v-icon> Billing</h1>
  <h5>Coming Soon...</h5>
  </div>
</template>

<script>
export default {
  name: 'billing-dashboard'
}
</script>
